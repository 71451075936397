import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appNumeric]'
})
// use with input type="text" or without type or with type="number" but for only integers
export class NumericDirective {

  @Input() appNumeric = 0;

  private readonly specialMetaKeys = ['A', 'C', 'V', 'X'];
  private specialKeys = [
    'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Enter', 'Control', 'Command'
  ];

  constructor(private el: ElementRef) {
  }

  @HostListener('input', ['$event'])
  @HostListener('change', ['$event'])
  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent): void {
    if (
      ((e.ctrlKey === true || e.metaKey === true) && (this.specialMetaKeys.indexOf(e.key.toUpperCase()) !== -1)) ||
      /Digit/.test(e.code) ||
      this.specialKeys.indexOf(e.key) !== -1
    ) {
      return;
    }
    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    if (this.appNumeric > 0 && this.el.nativeElement.selectionStart === null) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const tempNext: string[] = current.split('');
    tempNext.splice(this.el.nativeElement.selectionStart, 0, e.key);
    const next: string = tempNext.join('');

    if (next && !this.check(next, +this.appNumeric)) {
      e.preventDefault();
    }
  }

  private check = (value: string, appNumeric: number): boolean => {
    const regExpString = appNumeric <= 0 ? '\\d+' : `(\\d+)?(\\.)?(\\d{0,${appNumeric}})?`;
    const patternExp = new RegExp('^' + regExpString + '$');
    return patternExp.test(String(value));
  };
}

import { Component, forwardRef, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckAddressInterface } from '../../../domain/models/check-address.interface';
import { finalize } from 'rxjs/operators';
import { CheckAddressResponseInterface } from '../../../infrastructure/response/check-address-response.interface';
import { DictionaryService } from '../../services/dictionary.service';
import { Subscription } from 'rxjs';
import { AbstractForm } from '../../../../core/application/forms/abstract.form';
import _isEmpty from 'lodash-es/isEmpty';

@Component({
  selector: 'app-pin-control',
  templateUrl: './pin-control.component.html',
  styleUrls: ['./pin-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PinControlComponent),
      multi: true
    }
  ]
})
export class PinControlComponent implements OnDestroy {
  @Input() fb: AbstractForm;
  @Input() form: UntypedFormGroup;
  @Input() groupName?: string;
  @Input() withoutGst?: boolean;
  @Input() readonly?: boolean;
  disabled = false;
  value: string;
  addressCheckLoading: boolean;
  addressCheckSubscription: Subscription;
  isInit = true;

  constructor(private dictionaryService: DictionaryService) { }

  get isInvalid(): boolean {
    return this.form.get(this.getControlKey('pin')).value &&
      this.form.get(this.getControlKey('pin')).errors &&
      this.form.get(this.getControlKey('pin')).invalid ||
      (this.fb.isSubmitted() && this.form.get(this.getControlKey('pin')).invalid);
  }

  get isValid(): boolean {
    return this.form.get(this.getControlKey('pin')).value &&
      this.form.get(this.getControlKey('pin')).errors &&
      this.form.get(this.getControlKey('pin')).valid ||
      (this.fb.isSubmitted() && this.form.get(this.getControlKey('pin')).valid);
  }

  ngOnDestroy(): void {
    this.addressCheckSubscription?.unsubscribe();
  }

  onChange: any = () => {/*empty*/};
  onTouched: any = () => {/*empty*/};

  writeValue(value: string): void {
    this.value = value;
    this.onChange(this.value);

    if(!this.isInit) {
      this.checkPin();
    }
    this.isInit = false;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public getControlKey(name: string): string {
    return this.groupName ? `${this.groupName}.${name}` : name;
  }

  private checkPin(): void {
    const isValidPin = this.form.get(this.getControlKey('pin')).valid;
    const isValueGst = Boolean(this.form.get(this.getControlKey('gstNumber'))?.value);
    const errorsGst = this.form.get(this.getControlKey('gstNumber'))?.errors;
    let isValidGst = this.form.get(this.getControlKey('gstNumber'))?.value && this.form.get(this.getControlKey('gstNumber')).valid;

    if (isValidPin) {
      if (isValueGst && errorsGst && !this.withoutGst) {
        const gstApiError = ['gstInvalid', 'gstNotUnique', 'gstNotBelongState'];

        for (const key in errorsGst) {
          if (gstApiError.includes(key)) {
            delete errorsGst[key];
          }
        }

        this.form.get(this.getControlKey('gstNumber')).setErrors(_isEmpty(errorsGst) ? null : errorsGst);
        isValidGst = this.form.get(this.getControlKey('gstNumber'))?.value && this.form.get(this.getControlKey('gstNumber')).valid;
      }

      const payload = {
        pin: this.form.get(this.getControlKey('pin')).value,
      } as CheckAddressInterface;

      if(isValidGst && !this.withoutGst) {
        payload.gst = this.form.get(this.getControlKey('gstNumber')).value;
        payload.stateAbbr = this.form.get(this.getControlKey('state')).value;
      }

      this.addressCheckLoading = true;

      this.addressCheckSubscription = this.dictionaryService.addressCheck(payload)
        .pipe(
          finalize(() => this.addressCheckLoading = false),
        )
        .subscribe((response) => {
          this.checkPinFormHandler(response, isValidGst);
        }, () => {/*empty*/});
    }
  }

  private checkPinFormHandler(addressData: CheckAddressResponseInterface, isValidGst: boolean): void {
    if (!addressData.pinExist) {
      this.form.get(this.getControlKey('pin')).setErrors({ pinInvalid: true });
    } else {
      let cityKey = 'city';

      if(!this.form.get(this.getControlKey('city'))) {
        cityKey = 'cityName';
      }

      const group = {
        [cityKey]: addressData.cityName,
        state: addressData.stateAbbr,
      };

      if (this.groupName) {
        this.form.patchValue({
          [this.groupName]: group
        });
      } else {
        this.form.patchValue(group);
      }

      if(isValidGst && !this.withoutGst) {
        this.checkGstFormHandler(addressData);
      }
    }
  }

  private checkGstFormHandler(addressData: CheckAddressResponseInterface): void {
    switch (true) {
      case !addressData.gstValid:
        this.form.get(this.getControlKey('gstNumber')).setErrors({ gstInvalid: true });
        break;
      case !addressData.gstUnique:
        this.form.get(this.getControlKey('gstNumber')).setErrors({ gstNotUnique: true });
        break;
      case !addressData.gstBelongState:
        this.form.get(this.getControlKey('gstNumber')).setErrors({ gstNotBelongState: true });
        break;
    }
  }
}

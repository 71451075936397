import {
    AfterContentInit,
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';

const BASE_TIMER_DELAY = 0;

@Directive({
    selector: '[autofocus], [appAutofocus]',
})

export class AutofocusDirective implements AfterContentInit, OnChanges, OnDestroy
{
    @Input('appAutofocus') shouldFocusElement: any;
    @Input() autofocusDelay: number;

    private elementRef: ElementRef;
    private timer: any;

    constructor(elementRef: ElementRef)
    {

        this.elementRef = elementRef;

        this.shouldFocusElement = '';
        this.timer = null;
        this.autofocusDelay = BASE_TIMER_DELAY;

    }

    public ngAfterContentInit(): void
    {
        if (this.shouldFocusElement === '') {
            this.startFocusWorkflow();
        }
    }


    public ngOnChanges(changes: SimpleChanges): void
    {
        if (changes.autofocusDelay && (typeof (this.autofocusDelay) !== 'number')) {
            if (isNaN(this.autofocusDelay = +this.autofocusDelay)) {
                this.autofocusDelay = BASE_TIMER_DELAY;
            }
        }
        if (changes.shouldFocusElement) {
            if (this.shouldFocusElement) {
                this.startFocusWorkflow();
            } else {
                this.stopFocusWorkflow();
            }
        }
    }

    public ngOnDestroy(): void
    {
        this.stopFocusWorkflow();
    }

    private startFocusWorkflow(): void
    {
        if (this.timer) {
            return;
        }
        this.timer = setTimeout((): void => {
            this.timer = null;
            this.elementRef.nativeElement.focus();
        }, this.autofocusDelay);
    }

    private stopFocusWorkflow(): void
    {
        clearTimeout(this.timer);
        this.timer = null;
    }
}

import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import * as FormValidators from '../../../../core/application/forms/abstract.form';

@Component({
    selector: 'app-form-control-error',
    templateUrl: './form-control-error.component.html',
    styleUrls: ['./form-control-error.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({height: 0, opacity: 0}),
                        animate('.6s ease-out',
                            style({height: 16, opacity: 1}))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({height: 16, opacity: 1}),
                        animate('.6s ease-in',
                            style({height: 0, opacity: 0}))
                    ]
                )
            ]
        )
    ]
})
export class FormControlErrorComponent
{
    @Input() control: AbstractControl;
    @Input() formSubmit?: boolean;
    readonly validators = FormValidators;

    /**
     * @desc Checks if formControl has error and it should be displayed
     */
    hasError(): boolean
    {
        if (!this.control) {
            return false;
        }
        const {invalid, dirty} = this.control;

        return (this.formSubmit && invalid) || (invalid && dirty);
    }

    /**
     * @desc Check which pattern is being used
     * @param pattern
     */
    isPattern(pattern: RegExp): boolean
    {
        return this.control.errors.pattern.requiredPattern === String(pattern);
    }

}

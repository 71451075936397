<ngb-toast
    *ngFor="let toast of toastrService.toasts"
    [class]="toast.classname"
    [header]="toast?.header"
    [autohide]="isAutoHide(toast)"
    (mouseenter)="toast.autohide=false"
    (mouseleave)="toast.autohide=true"
    [delay]="toast.delay || 5000"
    (hidden)="toastrService.remove(toast)"
>
    <div class="d-flex justify-content-between">
        <div>
            <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
                <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
            </ng-template>
            <ng-template #text><span class="text-break">{{ toast.textOrTpl }}</span></ng-template>
        </div>

        <button type="button" class="btn-close end-0 ms-2" aria-label="Close" (click)="toastrService.remove(toast)">
            <span aria-hidden="true"></span>
        </button>
    </div>
</ngb-toast>

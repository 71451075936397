import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, QueryParamsHandling, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DEFAULT_ROUTE } from '../../../../constants/default-route';
import { DateFilterInterface } from '../../../shared/application/components/date-filter/date-filter.component';
import { DateUtil } from '../utils/date/date.util';

@Injectable({
    providedIn: 'root'
})
/**
 * @deprecated
 */
export class FiltersService implements OnDestroy
{
    static DATE_FILTER_CONST = 'period';
    static LOCAL_DATE_FILTER_CONST = 'localPeriod';

    public filters: any[] | any;
    public sorting;

    private readonly _period = new BehaviorSubject<DateFilterInterface>(FiltersService.periodFilters('local'));
    private readonly period$ = this._period.asObservable();

    private queryParamMapSubscribe: Subscription;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
    )
    {
        this.init();
    }

    get period(): DateFilterInterface {
        return this._period.getValue();
    }

    set period(val: DateFilterInterface) {
        this._period.next(val);
    }

    static periodFilters(type: 'local' | 'global'): DateFilterInterface
    {
        const localPeriodFilters = localStorage.getItem(FiltersService.LOCAL_DATE_FILTER_CONST);
        if (type === 'local' && localPeriodFilters) {
            return {...JSON.parse(localPeriodFilters)};
        }
        const periodFilter = localStorage.getItem(FiltersService.DATE_FILTER_CONST);
        if (!periodFilter) {
            return this.DEFAULT_PERIOD_FILTERS();
        }
        return JSON.parse(periodFilter);
    }

    static DEFAULT_PERIOD_FILTERS(days = 1, dateTime?: boolean): DateFilterInterface
    {
        const now = new Date();
        return ({
            from: dateTime ? DateUtil.toDateTime(DateUtil.addDays(now, -days)) : DateUtil.addDays(now, -days).toISOString().slice(0, 10),
            to: dateTime ? DateUtil.toDateTime(now, true) : now.toISOString().slice(0, 10),
        });
    }

    static get isPageWithPeriod(): boolean
    {
        return window.location.pathname === DEFAULT_ROUTE.dashboard ||
            /^\/sales/.test(window.location.pathname);
    }

    ngOnDestroy(): void
    {
        if (this.queryParamMapSubscribe) {
            this.queryParamMapSubscribe.unsubscribe();
        }
    }

    applyPeriod = (period: DateFilterInterface, callback?, queryParamsHandling: QueryParamsHandling = 'merge' ): void =>
    {
        const queryParams: {
            period: string;
            filters?: string;
            sorting?: string;
        } = {
            period: JSON.stringify(period),
        };
        if (this.filters) {
            Object.assign(queryParams, {filters: Object.keys(this.filters).length ? btoa(JSON.stringify(this.filters)) : null});
        }
        if (this.sorting) {
            Object.assign(queryParams, {sorting: this.sorting.length ? btoa(JSON.stringify(this.sorting)) : null});
        }
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling,
        });
    };

    applyGridFilters(api, columnApi)
    {
        // set grid sorting
        columnApi.getColumnState(this.sorting);

        // set grid filters
        setTimeout(() => {
            api.setFilterModel(this.filters);
        });
    }

    onGridChanged = (type: 'sorting' | 'filters', api, rowModelType?) =>
    {
        if (!['sorting', 'filters'].includes(type)) {
            return;
        }

        if (rowModelType === undefined) {
            this[type] = type === 'sorting' ? api.getColumnState() : api.getFilterModel();
            this.applyPeriod(FiltersService.periodFilters('local'));
        }
    };

    private init()
    {
        if (FiltersService.isPageWithPeriod) {
            return ;
        }
        // add period if need (empty or incorrect period in url)
        this.queryParamMapSubscribe = this.route.queryParamMap.subscribe((queryParams: ParamMap) => {
            localStorage.removeItem(FiltersService.LOCAL_DATE_FILTER_CONST);
            if (queryParams.has('filters')) {
                this.filters = JSON.parse(atob(queryParams.get('filters')));
            }
            if (queryParams.has('sorting')) {
                this.sorting = JSON.parse(atob(queryParams.get('sorting')));
            }
            this.processApiPeriodFilters(queryParams.get(FiltersService.DATE_FILTER_CONST));
        });
    }

    private processApiPeriodFilters(rawPeriod, withApply?: boolean): void
    {
        if (!rawPeriod) { /*window.location.pathname !== DEFAULT_ROUTE.dashboard &&*/
            if (withApply) {
                return this.applyPeriod(FiltersService.periodFilters('local'));
            }
            return ;
        }
        const parsedPeriod = JSON.parse(rawPeriod);
        const expectedDate = '\\d{4}-\\d{2}-\\d{2}';
        if (!('from' in parsedPeriod) || !new RegExp(expectedDate).test(parsedPeriod.from)
            || !('to' in parsedPeriod) || !new RegExp(expectedDate).test(parsedPeriod.to)) {
            localStorage.removeItem(FiltersService.LOCAL_DATE_FILTER_CONST);
            if (withApply) {
                return this.applyPeriod(FiltersService.periodFilters('local'));
            }
            return ;
        }
        this.period = parsedPeriod;
        if (withApply) {
            this.applyPeriod(parsedPeriod);
        }
        localStorage.setItem(FiltersService.LOCAL_DATE_FILTER_CONST, rawPeriod);
    }
}

import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

// 'sortColumnConfig'   - { iteratees: string | string[], reverse: boolean }
// 'value'              - compare with sortColumnConfig.iteratees

// <th
//     [appSortColumn]="value"
//     [sortColumnConfig]="sortColumnConfig"
//     (processingSort)="sortColumnConfig = $event">
//     Part Number (PN)
// </th>

@Directive({
  selector: '[appSortColumn]'
})
export class SortColumnDirective
{
    @Input() public appSortColumn: string | string[];
    @Input() public sortColumnConfig: { iteratees?: string | string[]; reverse?: boolean };
    @Input() public removable?: boolean;
    @Output() public processingSort: EventEmitter<any> = new EventEmitter();

    constructor() {/*empty*/}

    @HostBinding('class.sorting') get isDefaultClass(): boolean
    { return this.sortColumnConfig && this.appSortColumn !== this.sortColumnConfig.iteratees; }

    @HostBinding('class.sorting_desc') get isDescClass(): boolean
    {
        return this.sortColumnConfig &&
            this.sortColumnConfig?.reverse !== undefined &&
            this.sortColumnConfig?.reverse &&
            this.appSortColumn === this.sortColumnConfig?.iteratees;
    }

    @HostBinding('class.sorting_asc') get isAscClass(): boolean
    {
        return this.sortColumnConfig &&
            this.sortColumnConfig?.reverse !== undefined &&
            !this.sortColumnConfig?.reverse &&
            this.appSortColumn === this.sortColumnConfig?.iteratees;
    }

    @HostListener('click') public onClick(): void
    {
        if (
            this.removable &&
            this.sortColumnConfig.iteratees === this.appSortColumn &&
            this.sortColumnConfig.reverse
        ) {
            return this.processingSort.emit({});
        }
        else {
            this.sortColumnConfig.reverse = !this.sortColumnConfig?.reverse;
        }

        if (this.sortColumnConfig.iteratees !== this.appSortColumn) {
            this.sortColumnConfig.iteratees = this.appSortColumn;
            this.sortColumnConfig.reverse = false;
        }

        this.processingSort.emit({
            iteratees: this.appSortColumn,
            reverse: this.sortColumnConfig.reverse,
        });
    }}

<div class="table-wrapper overflow-auto">
    <table class="table table-striped table-hover">
        <thead>
        <tr>
            <th>
                <app-th [colKey]="'eventName'"
                        [config]="cols.eventName"
                        [name]="cols.eventName.filter.title"
                        (update)="filterData()"
                        [queryBuilder]="queryBuilder"
                        (sort)="sort($event)"
                        [showAllOption]="true"
                        [inputWidth]="'175px'"
                        [multiselect]="true"
                        [options]="eventList"
                ></app-th>
            </th>
            <th class="w-150px">
                <app-th [colKey]="'date'"
                        [config]="cols.date"
                        [queryBuilder]="queryBuilder"
                        [name]="cols.date.filter.title"
                        (update)="filterData()"
                        (sort)="sort($event)"
                ></app-th>
            </th>
            <th>
                Message
            </th>
            <th>
                <app-th [colKey]="'author'"
                        [config]="cols.author"
                        [name]="cols.author.filter.title"
                        (update)="filterData()"
                        [queryBuilder]="queryBuilder"
                        (sort)="sort($event)"
                ></app-th>
            </th>
            <th>
                Context
            </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of eventHistory | paginate: {itemsPerPage: queryBuilder.getLimit(), currentPage: queryBuilder.getPage(), id: 'event_history_grid' }">
            <tr>
                <td class="actions">
                    <small>{{ item.eventName }}</small>
                </td>
                <td class="w-150px">
                    {{ getDate(item.date) | bdate:'dd-MM-yyyy HH:mm:ss' }}
                </td>
                <td>
                    {{ item.message }}
                </td>
                <td>
                    {{ item.author }}
                </td>
                <td>
                    <app-debug-target [info]="item?.context"
                                      [title]="item.eventName"
                    ></app-debug-target>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</div>

<app-tfoot [dataList]="eventHistory"
           id="event_history_grid"
           [queryBuilder]="queryBuilder"
           (changePageNum)="queryBuilder.changePageNum($event)"
           [useMoreItems]="true"
></app-tfoot>

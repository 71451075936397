<div class="input-group" #myDrop="ngbDropdown" ngbDropdown [container]="container">
    <input type="text"
           class="form-control form-control-sm cursor-pointer"
           ngbDropdownAnchor
           (click)="$event.stopPropagation(); myDrop?.open();"
           [(ngModel)]="searchValue"
           [disabled]="disabled"
           [placeholder]="placeholder || 'Type something...'"
           (ngModelChange)="search()">
    <fa-icon class="caret-icon" icon="caret-down"></fa-icon>
    <ul class="dropdown-menu dropdown-custom p-0" ngbDropdownMenu style="width: 100%">
        <li class="dropdown-item cursor-pointer"
            *ngFor="let option of filteredOptions"
            (click)="writeValue(getValue(option))"
        >
            {{ getLabel(option) }}
        </li>
    </ul>
</div>

<div *ngIf="dataList?.length"
     class="d-sm-flex align-items-center justify-content-between text-center text-sm-start m-2">
    <label class="form-label text-nowrap text-muted mb-0"
           [ngClass]="useMoreItems ? '' : 'd-none'"
    >
        Items on page:
        <ng-select class="ng-select-sm without-validation-icon w-80px d-inline-block"
                   [ngModel]="limit"
                   [clearable]="false"
                   [searchable]="false"
                   (change)="changeItemsPerPage($event)"
                   appendTo="body"
        >
            <ng-option *ngFor="let option of [10,25,50,100, 250, 500]" [value]="option">{{option}}</ng-option>
        </ng-select>
    </label>
    <div class="text-muted">
        Showing {{(page - 1) * limit + 1}} to {{totalItemsLength < page * limit ? totalItemsLength : page * limit}}
        <ng-container *ngIf="totalItemsLength !== null; else allLength">
            of {{totalItemsLength}} entries
        </ng-container>
        <ng-template #allLength>{{dataList?.length}}</ng-template>
    </div>
    <pagination-controls class="main-pagination"
                         *ngIf="totalItemsLength === null || totalItemsLength > limit"
                         (pageChange)="changePageNum.emit($event)"
                         [maxSize]="7"
                         [id]="id"
                         [nextLabel]="getNextLabel"
                         [directionLinks]="false"
                         screenReaderPaginationLabel="Pagination"
                         screenReaderPageLabel="page"
                         screenReaderCurrentLabel="You're on page"
    ></pagination-controls>
</div>
<div *ngIf="!dataList?.length" class="table-info {{loader ? 'loading' : 'no-data'}}"></div>

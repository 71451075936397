import { Pipe, PipeTransform } from '@angular/core';
import { TariffPlanList } from "../../../../billing/infrastructure/response/tariff-plans.response";
@Pipe({
    name: 'sortCustomerTariffPlans'
})
export class SortCustomerTariffPlans implements PipeTransform {
    transform(value: TariffPlanList[]): TariffPlanList[] {
        const mapServiceCode = ['PD', 'NPS'];

        return value.sort((n1,n2) => mapServiceCode.indexOf(n1.serviceCode) - mapServiceCode.indexOf(n2.serviceCode));
    }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class DebugStorageService
{
    debugInfoSource$: Subject<{data?: any; openPanel?: boolean; title?: string}> = new Subject();
    debugInfo$: Observable<{data?: any; openPanel?: boolean; title?: string}>;

    constructor()
    {
        this.debugInfo$ = this.debugInfoSource$.asObservable();
    }
}

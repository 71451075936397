import { Injectable } from '@angular/core';
import { Loader } from "@googlemaps/js-api-loader";
import { Observable, of } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})

export class LocationRepository {
  loadMap = (): Observable<Loader> => of(new Loader({
          apiKey: environment.googleMapsKey,
          version: "weekly",
          libraries: ["places"]
      }));
}

// import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import _snakeCase from 'lodash-es/snakeCase';

@Pipe({
    name: 'bTitleCase'
})
// export class BTitleCasePipe extends TitleCasePipe implements PipeTransform
export class BTitleCasePipe implements PipeTransform
{
    transform(value: string): string;
    transform(value: null | undefined | unknown): null;
    transform(value: string | null | undefined): string | null;
    transform(value: string): string
    {
        value = _snakeCase(value).replace(new RegExp('_', 'ig'), ' ');
        return (value) ? value.charAt(0)?.toUpperCase() + value.substr(1)?.toLowerCase() : '';
        // return super.transform(value);
    }
}

import {
    Component,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output, SimpleChanges,
} from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from '../../../../core/application/utils/date/date.parser';
import { FiltersService } from '../../../../core/application/services/filters.service';
import { DateUtil } from '../../../../core/application/utils/date/date.util';


@Component({
    selector: 'app-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss'],
    providers: [
        CustomDateParserFormatter
    ]
})
export class DateFilterComponent implements OnInit, OnChanges
{
    @Input() filters: DateFilterInterface;
    @Input() filterKey?: string;
    @Input() class?: string;
    @Input() disabled: boolean;
    @Input() useTime?: boolean;
    @Input() labelPrefix = 'Date';
    @Input() dateFromPlaceholder = 'Date From';
    @Input() dateToPlaceholder = 'Date To';
    @Input() showLabels = true;
    @Input() showApplyButton = true;
    @Input() showClearButton = false;
    @Output() applyFilters: EventEmitter<any> = new EventEmitter();
    @Output() filtersChanged: EventEmitter<DateFilterInterface> = new EventEmitter<DateFilterInterface>();

    minDate: NgbDateStruct;
    minToDate: NgbDateStruct;
    maxDate: NgbDateStruct;
    maxFromDate: NgbDateStruct;
    time: {
        from: string;
        to: string;
    } = {
        from: '00:00:00',
        to: '23:59:59',
    }

    constructor(public formatter: CustomDateParserFormatter)
    {}

    static getFiltersWithTime(filters?: DateFilterInterface): DateFilterInterface
    {
        return {
            from: DateUtil.toDateTime(filters?.from ?? (new Date())),
            to: DateUtil.toDateTime(filters?.to ?? (new Date()), true),
        };
    }

    ngOnInit()
    {
        setTimeout(() => {
            this.filters = this.filters ? this.filters : FiltersService.periodFilters('local');
            this.setDefaultRangeDate();
            this.updateMinMaxDates();
        });
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        this.updateMinMaxDates();
    }

    applyFiltersEmit = (): void => {
        this.applyFilters.emit({...this.filters});
    };

    clearFilters = (): void => {
        this.setDefaultRangeDate();
        this.clearFrom();
        this.clearTo();
        this.applyFiltersEmit();
    };

    clearFrom = (): void => {
        this.filters.from = '';
        this.time.from = '00:00:00';
    }
    clearTo = (): void => {
        this.filters.to = '';
        this.time.to = '23:59:59';
    }

    emitFiltersChanged(): void {
        if (this.useTime) {
            return this.filtersChanged.emit(this.dateWithTime);
        }
        this.filtersChanged.emit({...this.filters});
    }

    get dateWithTime(): DateFilterInterface
    {
        return {
            from: (this.filters.from && this.filters.from + 'T' + this.time.from) || null,
            to: (this.filters.to && this.filters.to + 'T' + this.time.to) || null,
        };
    }

    dateSelectChange(type: string) {
        switch (type) {
            case 'from':
                this.minToDate = this.formatter.parse(this.filters[type], true);
                break;
            case 'to':
                this.maxFromDate = this.formatter.parse(this.filters[type], true);
                break;
        }
    }

    private updateMinMaxDates(): void
    {
        this.minToDate = this.formatter.parse(this.filters.from ?? '1970-01-01', true);
        this.maxFromDate = this.formatter.parse(this.filters.to, true);
    }

    private setDefaultRangeDate(): void
    {
        this.minDate = this.formatter.parse('1970-01-01', true);
        this.minToDate = this.minDate;
        const date = new Date();
        this.maxDate = this.formatter.parse((new Date(date.setFullYear(2999))).toISOString().slice(0, 10), true);
        this.maxFromDate = this.maxDate;
    }
}

export interface DateFilterInterface {
    from: string;
    to: string;
}

<button type="button" class="btn btn-primary btn-sm ms-2 position-relative"
        (click)="toggleFilters.emit()"
        [attr.aria-expanded]="!collapsed"
        aria-controls="collapseFilters"
>
    <fa-icon icon="filter"></fa-icon>
    <div class="position-absolute badge rounded-pill bg-danger applied-filters-count"
         *ngIf="appliedFiltersCount"
    >{{appliedFiltersCount}}</div>
</button>

<div class="mb-3 row">
    <label class="col-form-label col-sm-3" [for]="controlKey">{{ label }}:</label>
    <ng-container [ngSwitch]="type">
        <div class="col-md-9" *ngSwitchCase="'select'">
            <select class="form-select form-select-sm"
                    [id]="controlKey"
                    [required]="required"
                    [(ngModel)]="value"
                    (ngModelChange)="writeValue($event)">
                <option [ngValue]="null">Choose {{ label }}</option>
                <option *ngFor="let option of options"
                        [value]="option.key">{{ option.value }}</option>
            </select>
            <div class="invalid-feedback" *ngIf="form.getError('required', errorPath + '.' + controlKey)">
                {{fb.getErrorMsg('required')}}
            </div>
        </div>

        <div class="col-sm-9" *ngSwitchCase="'text'">
            <input type="text"
                   [id]="controlKey"
                   class="form-control form-control-sm"
                   [(ngModel)]="value"
                   (ngModelChange)="writeValue($event)"
                   [required]="required"
            />
            <div class="invalid-feedback" *ngIf="form.getError('required', errorPath + '.' + controlKey)">
                {{fb.getErrorMsg('required')}}
            </div>
        </div>

        <div class="col-sm-9" *ngSwitchCase="'textarea'">
            <textarea
                   [id]="controlKey"
                   class="form-control form-control-sm"
                   [(ngModel)]="value"
                   (ngModelChange)="writeValue($event)"
                   [required]="required"
            ></textarea>
            <div class="invalid-feedback" *ngIf="form.getError('required', errorPath + '.' + controlKey)">
                {{fb.getErrorMsg('required')}}
            </div>
        </div>

        <div class="col-md-9" *ngSwitchCase="'password'">
            <input type="password"
                   [(ngModel)]="value"
                   (ngModelChange)="writeValue($event)"
                   [id]="controlKey"
                   autocomplete="new-password"
                   class="form-control form-control-sm"
                   [required]="required"
            />
            <div class="invalid-feedback" *ngIf="form.getError('required', errorPath + '.' + controlKey)">
                {{fb.getErrorMsg('required')}}
            </div>
        </div>

        <div class="col-md-9" *ngSwitchCase="'checkbox'">
            <input type="checkbox"
                   class="form-check-input"
                   [id]="controlKey"
                   [(ngModel)]="value"
                   (ngModelChange)="writeValue($event)">
        </div>
    </ng-container>
</div>

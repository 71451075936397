import { Component, Input } from '@angular/core';
import { DateUtil } from '../../../../core/application/utils/date/date.util';
import { NoteInterface } from '../../../domain/models/note.interface';

@Component({
    selector: 'app-notes-grid',
    templateUrl: './notes-grid.component.html',
    styleUrls: ['./notes-grid.component.scss']
})
export class NotesGridComponent
{

    @Input() readonly notes: NoteInterface[];

    noteTableConfig = {
        currentPage: 1,
        itemsPerPage: 10,
        id: 'notes_grid'
    };
    getDate = DateUtil.getDate;

    constructor() {/*empty*/}
}

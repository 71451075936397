import { Component, Input } from '@angular/core';
import { DebugStorageService } from '../../../../core/application/services/debug-storage.service';
import { ItemEventHistoryInterface } from '../../../domain/models/event-history.interface';

@Component({
    selector: 'app-debug-target',
    templateUrl: './debug-target.component.html',
    styleUrls: ['./debug-target.component.scss']
})
export class DebugTargetComponent
{
    @Input() info: Record<any, any>;
    @Input() title?: string;

    constructor(public debugStorage: DebugStorageService) {}

    get canShowInDebugPanel(): boolean
    {
        return Array.isArray(this.info) && this.info?.length > 0 || typeof this.info === 'object' && Object.keys(this.info)?.length > 0;
    }

    debug(data: Record<string, any> | ItemEventHistoryInterface[])
    {
        this.debugStorage.debugInfoSource$.next({data, openPanel: true, title: this.title});
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform
{
    transform(value: any, config?: { short?: boolean }): any
    {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            // less than 30 seconds ago will show as 'Just now'
            if (seconds < 29) {
                return 'Just now';
            }
            const intervals = {
                year: 31536000,
                month: 2592000,
                week: 604800,
                day: 86400,
                hour: 3600,
                minute: 60,
                second: 1
            };
            const shortMap = {
                month: 'm-th',
                week: 'w',
                day: 'd',
                hour: 'h',
                minute: 'm',
                second: 's'
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0) {
                    return `${counter} ${config?.short ? shortMap[i] || i : i}${counter === 1 ? '' : 's'}${(config?.short ? '' : ' ago')}`;
                }
            }
        }
        return value;
    }
}

import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DateUtil } from '../../../../../core/application/utils/date/date.util';
import { FilterQueryBuilder } from '../../../../../core/application/utils/query-builder/filter-query-builder';
import { ColumnInterface, ConfigInterface } from '../../../../../shared/application/components/tables/th/th.component';
import { OrderByPipe } from '../../../../../shared/application/pipes/order-by/order-by.pipe';
import { UiFilterConfigInterface } from './ui-filter-config.interface';
import _cloneDeep from 'lodash-es/cloneDeep';

export class UiFilter<T>
{


    public filterChanged$: Observable<T[]>;

    private readonly filterChangedSource$ = new BehaviorSubject<T[]>(null);

    private _initialItems: T[] = [];

    constructor(private items: T[] = [], private filterConfig: UiFilterConfigInterface[],  private queryBuilder: FilterQueryBuilder)
    {
        this._initialItems = _cloneDeep(this.items);
        this.filterChanged$ = this.filterChangedSource$.asObservable().pipe(filter((v) => !!v));
    }

    static GENERATE_FILTER_CONFIG<T>(cols: {[key: string]: ColumnInterface}): UiFilterConfigInterface[] {
        return Object.keys(cols).reduce((result, fieldName) => {
            const {
                type,
                uiType,
                usePice,
                dateWithTime,
                rangePrefix,
            } = cols[fieldName].filter;
            switch (true) {
                case type === 'dateRange':
                    return [
                        ...result,
                        {fieldName, type: 'dateFrom', filterField: `${fieldName}From`, dateWithTime},
                        {fieldName, type: 'dateTo', filterField: `${fieldName}To`, dateWithTime},
                    ];
                case type === 'numberRange':
                    return [
                        ...result,
                        {fieldName, type: 'lessThan', filterField: `${fieldName}From`, usePice, rangePrefix },
                        {fieldName, type: 'moreThan', filterField: `${fieldName}To`, usePice, rangePrefix },
                    ];
                case type === 'ngSelect':
                    result.push({fieldName, type: 'multipleSelect', filterField: fieldName});
                    return result;
                case uiType === 'stringEqual':
                    result.push({fieldName, type: 'stringEqual', filterField: fieldName});
                    return result;
                case uiType === 'boolean':
                    result.push({fieldName, type: 'boolean', filterField: fieldName});
                    return result;
                default:
                    result.push({fieldName, type: 'text', filterField: fieldName});
                    return result;
            }
        }, []);
    }

    get initialItems(): T[]
    {
        return this._initialItems;
    }

    public filter(items?: T[]): void
    {
        if (items) {
            this._initialItems = _cloneDeep(items);
        }

        this.items = (this._initialItems || []).filter((item) => (this.filterConfig.every((c) => {
            const value =  this.queryBuilder.getFilters()[c.filterField]?.[0];
            let itemValue =  item[c.fieldName];
            const values = this.queryBuilder.getFilters()[c.filterField] as string[];

            switch (c.type) {
                case 'multipleSelect':
                    return values === undefined || values === null || values.includes(itemValue);
                case 'select':
                    return  value === undefined || value === '' || item[c.selectKey].id === value;
                case 'text':
                    return value == null || value === '' ||
                      String(itemValue).toLowerCase().includes(value.toLowerCase());
                case 'dateFrom': {
                    if (typeof itemValue === 'number' ) {
                        itemValue = DateUtil.convertTimestampToDate(itemValue);
                    }
                    return value == null || value === '' ||
                      DateUtil.datesComparison('sameOrAfter', itemValue as unknown as Date, new Date(value), !c?.dateWithTime);
                }
                case 'dateTo': {
                    if (typeof itemValue === 'number' ) {
                        itemValue = DateUtil.convertTimestampToDate(itemValue);
                    }
                    return value == null || value === '' ||
                      DateUtil.datesComparison('sameOrBefore', itemValue as unknown as Date, new Date(value), !c?.dateWithTime);
                }
                case 'lessThan': {
                    const normalizedValue = c.rangePrefix?.length
                        ? item[c.rangePrefix[0]]
                        : c.usePice
                            ? Number(itemValue) / 100
                            : Number(itemValue);
                    return value == null || value === '' || +value <= normalizedValue;
                }
                case 'moreThan': {
                    const normalizedValue = c.rangePrefix?.length
                        ? item[c.rangePrefix[1]]
                        : c.usePice
                            ? Number(itemValue) / 100
                            : Number(itemValue);
                    return value == null || value === '' || +value >= normalizedValue;
                }
                case 'stringEqual':
                    return value == null || value === '' ||
                      (value as unknown as string).toLowerCase() === (itemValue as unknown as string).toLowerCase();
                case 'boolean':
                    return value == null || value === '' || value === itemValue;
            }
        })));
        this.filterChangedSource$.next(this.items);
    }

    public sort(sortCol: ConfigInterface): void
    {
        this.items = new OrderByPipe().transform(this.items, sortCol?.name, !sortCol?.value);
        // if (sortCol.value) {
        //     this.sortDesc(sortCol.name);
        // } else {
        //     this.sortAsc(sortCol.name);
        // }
        this.filterChangedSource$.next(this.items);
    }

    public clearFilters(): void {
        this.items = this._initialItems;
        this.filterChangedSource$.next(this.items);
    }

    // private sortAsc(field: string): void
    // {
    //     this.items = this.items.sort((a,b) => {
    //         const firstField = this.prepareValue(a[field]);
    //         const secondField = this.prepareValue(b[field]);
    //
    //         return (firstField > secondField) ? 1 : ((secondField > firstField) ? -1 : 0);
    //     });
    // }
    //
    // private sortDesc(field: string): void
    // {
    //     this.items = this.items.sort((a,b) => {
    //         const firstField = this.prepareValue(a[field]);
    //         const secondField = this.prepareValue(b[field]);
    //
    //         return (firstField < secondField) ? 1 : ((secondField < firstField) ? -1 : 0);
    //     });
    // }
    //
    // private prepareValue(value: any): any
    // {
    //     return !Number.isNaN(+value) ? +value : typeof value === 'string' ? value.toLowerCase() : value;
    // }

}

import { Component, Input, OnInit } from '@angular/core';
import { FullPartNamePipe } from '../../pipes/full-part-name/full-part-name.pipe';

@Component({
    selector: 'app-part-link',
    templateUrl: './part-link.component.html',
    styleUrls: ['./part-link.component.scss']
})
export class PartLinkComponent implements OnInit
{

    @Input() item: {
        partId?: number;
        partNumber?: string;
    };
    @Input() partId?: number | null;
    @Input() partNumber?: string;
    @Input() name?: string;
    @Input() nameFromArray?: string[];
    @Input() styled?: boolean;
    preparedName: string;

    constructor() {/*empty*/}

    public ngOnInit(): void
    {
        this.partId ??= this.item?.partId ?? null;
        this.partNumber ??= this.item?.partNumber ?? null;
        this.name ??= this.partNumber;
        setTimeout(() => {
            this.preparedName = new FullPartNamePipe().transform(this.nameFromArray) || this.name;
        });
    }
}

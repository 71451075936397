import { Injectable } from '@angular/core';
import { Loader } from "@googlemaps/js-api-loader";

import { LocationRepository } from '../../infrastructure/repositories/location.repository';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class LocationService
{
    private _loader: Loader;
  constructor(
    private locationRepository: LocationRepository,
  )
  {}

  isMapLoaded(): boolean {
    return typeof (window as any)?.google === 'object' && typeof (window as any)?.google?.maps === 'object';
  }

  loadMap = (): Observable<Loader> => {
    if (this.isMapLoaded()) {
      console.log('loadMap loaded');
      return of(this._loader);
    }

    console.log('loadMap start');

    return this.locationRepository.loadMap()
      .pipe(
        map((loader) => {
          loader.importLibrary('maps');
          this._loader = loader;
          console.log('loadMap success', loader);
          return loader;
        }),
        // catchError((err) => {
        //   console.log('loadMap error', err);
        //   return of(false);
        // })
      );
  }
}

<div class="table-wrapper overflow-auto">
    <table class="table table-striped table-hover">
        <thead>
        <tr>
            <th class="w-150px">Author</th>
            <th class="w-125px">Date</th>
            <th class="w-150px">Note</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let note of notes | paginate: noteTableConfig">
            <tr>
                <td class="w-150px">
                    {{ note.author }}
                </td>
                <td class="w-125px">
                    {{ getDate(note.date) | bdate:'dateTime' }}
                </td>
                <td class="w-150px">
                    {{ note.message }}
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</div>

<app-tfoot [dataList]="notes"
           id="notes_grid"
           [currentPage]="noteTableConfig.currentPage"
           [itemsPerPage]="noteTableConfig.itemsPerPage"
           (changePageNum)="noteTableConfig.currentPage=$event"
           (changeLimit)="noteTableConfig.itemsPerPage=$event"
           [useMoreItems]="true"
></app-tfoot>

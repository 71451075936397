import { QueryBuilderInterface } from './query-builder';
import { HttpParams } from '@angular/common/http';

export abstract class QueryBuilderCore implements QueryBuilderInterface
{
    protected request: Record<string, unknown> = {};

    protected requestSet = [];

    public setRequestSet(requestSet: string[]): void
    {
        this.requestSet = requestSet;
    }

    public getRequestObject(): Record<string, unknown>
    {
        this.buildRequestObject();

        return this.request;
    }

    abstract buildRequestObject(): void;

    abstract buildHttpParams(): { params: HttpParams };
}

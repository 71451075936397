import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appMaxNumber]'
})
export class MaxNumberDirective
{

    @Input() appMaxNumber: number;
    @Input() allowDecimal = true;

    private readonly specialMetaKeys = ['A', 'C', 'V', 'X'];
    private readonly specialKeys = [
        'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Enter'
    ];

    private _regExp: RegExp;

    constructor(private el: ElementRef, private control: NgControl)
    {
        this.setRegExp();
    }

    @HostListener('input', ['$event'])
    @HostListener('change', ['$event'])
    onInput(e: Event): void
    {
        const value = +(e.target as HTMLInputElement).value;

        if ( typeof this.appMaxNumber === 'number' && value > this.appMaxNumber) {
            this.control.valueAccessor.writeValue(this.appMaxNumber);
            this.control.control.patchValue(this.appMaxNumber);
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent): void
    {
        if (
            ((e.ctrlKey === true || e.metaKey === true) && (this.specialMetaKeys.indexOf(e.key.toUpperCase()) !== -1)) ||
            /Digit/.test(e.code) ||
            this.specialKeys.indexOf(e.key) !== -1
        ) {
            return;
        }
        if (!this.checkIfValidDecimal(e) || !(/^[0-9]+$/i.test(e.key))) {
            e.preventDefault();
        }
    }

    private checkIfValidDecimal(e: KeyboardEvent): boolean
    {
        return this.allowDecimal
            ? (e.key === '.' && this.control.value?.length && !this.control.value?.includes('.'))
            : e.key !== '.';
    }

    private setRegExp(): void
    {
        if (this.allowDecimal) {
            this._regExp = new RegExp(`^\\d+(.\\d{0,${2}})?$`);
        } else {
            this._regExp = /^\d+$/;
        }
    }
}

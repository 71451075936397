<div class="modal-header">
    <h5 class="modal-title">
        Delivery Location
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
    <div class="map-modal__body">
        <app-loader *ngIf="mapLoading"></app-loader>
        <div *ngIf="mapLoaded">
            <google-map width="100%"
                        [height]="heightMap"
                        [center]="center"
                        [options]="mapOptions"
                        [zoom]="zoom"
            >
                <map-marker [position]="position" [options]="markerOptions"></map-marker>
            </google-map>
        </div>
    </div>

</div>

<div class="modal-footer">
    <div class="m-0 mt-2 text-end">
        <button type="button" class="btn btn-outline-secondary btn-sm me-2" (click)="activeModal.dismiss()">Close</button>
    </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'indianCurrency'
})
export class IndianCurrencyPipe implements PipeTransform
{

    transform(value: number, args?: configInterface): any
    {
        if (!value && value !== 0) {
            return null;
        }
        const hidePennies = args?.hidePennies;
        const usePice = args?.usePice ?? true;
        if (usePice) {
            value /=100;
        }
        const result = value.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: hidePennies ? 0 : 2,
            maximumFractionDigits: hidePennies ? 0 : 2
        });
        return !args?.usePrefix? result : `${value > 0 ? '+' : ''}${result}`;

    }
}

interface configInterface {
    hidePennies?: boolean;
    usePice?: boolean;
    usePrefix?: boolean;
}

import {
    Component,
    EventEmitter, Injectable,
    Input,
    OnInit,
    Output, ViewChild,
} from '@angular/core';
import {
    NgbDate,
    NgbDateAdapter,
    NgbDateParserFormatter,
    NgbDatepickerNavigateEvent,
    NgbDateStruct,
    NgbInputDatepicker
} from '@ng-bootstrap/ng-bootstrap';
import { FiltersService } from '../../../../core/application/services/filters.service';
import { BdatePipe } from '../../pipes/bdate/bdate.pipe';


const MIN_DATE = {
    day: 1,
    month: 1,
    year: 1970,
};

const MAX_DATE = {
    day: 31,
    month: 12,
    year: 2999,
};
/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable({
    providedIn: 'root',
})
export class CustomMonthAdapter extends NgbDateAdapter<string>
{
    fromModel(value: string | null): NgbDateStruct | null
    {
        if (!value) {
            return null;
        }
        return {
            day: 1,
            month: +value.slice(4),
            year: +value.slice(0, 4),
        };
    }

    toModel(date: NgbDateStruct | null): string | null
    {
        if (!date) {
            return null;
        }
        const {year, month} = date;
        return `${year}${month.toString().padStart(2, '0')}`;
    }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable({
    providedIn: 'root',
})
export class CustomMonthParserFormatter extends NgbDateParserFormatter
{
    parse(value: string): NgbDateStruct | null
    {
        if (!value) {
            return null;
        }

        const date = new Date(Date.parse(`${value} Z`));
        return {
            day: 1,
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        };
    }
    format(date: NgbDateStruct | null): string
    {
        if (!date) {
            return '';
        }
        return new BdatePipe().transform(new Date(date.year, date.month - 1), 'monthYear');
    }

}

@Component({
    selector: 'app-month-filter',
    templateUrl: './month-filter.component.html',
    styleUrls: ['./month-filter.component.scss'],
    providers: [
        // { provide: NgbDateAdapter, useClass: CustomAdapter },
        // { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    ],
})
export class MonthFilterComponent implements OnInit
{
    @Input() filters: DateFilterInterface;
    @Output() applyFilters: EventEmitter<any> = new EventEmitter();
    @Output() filtersChanged: EventEmitter<DateFilterInterface> = new EventEmitter<DateFilterInterface>();
    @ViewChild('dateFrom') dateFrom: NgbInputDatepicker;
    @ViewChild('dateTo') dateTo: NgbInputDatepicker;

    readonly FROM_PLACEHOLDER = 'From';
    readonly TO_PLACEHOLDER = 'Till';

    minDate: NgbDateStruct;
    minToDate: NgbDateStruct;
    maxDate: NgbDateStruct;
    maxFromDate: NgbDateStruct;
    // dateFromValue: string;
    // dateToValue: string;

    constructor(
        public formatter: CustomMonthParserFormatter,
        public dateAdapter: CustomMonthAdapter,
        // private calendar: NgbCalendar,
        // private cd: ChangeDetectorRef,
    )
    {
    }

    ngOnInit()
    {
        setTimeout(() => {
            this.filters = this.filters ? this.filters : FiltersService.DEFAULT_PERIOD_FILTERS();
            // const from = this.dateAdapter.fromModel(this.filters.from) ?? this.calendar.getToday();
            // const to = this.dateAdapter.fromModel(this.filters.to) ?? this.calendar.getToday();
            // this.dateFromValue = `${from.day.toString().padStart(2, '0')}-${from.month.toString().padStart(2, '0')}-${from.year}`;
            // this.dateToValue = `${to.day.toString().padStart(2, '0')}-${to.month.toString().padStart(2, '0')}-${to.year}`;
            this.setDefaultRangeDate();
        });
    }

    applyFiltersEmit = (): void => {
        this.applyFilters.emit({...this.filters});
    };

    clearFilters = (): void => {
        this.setDefaultRangeDate();
        this.filters = {
            from: '',
            to: '',
        };
        this.applyFiltersEmit();
    };

    emitFiltersChanged(): void
    {
        this.filtersChanged.emit({...this.filters});
    }

    dateSelectChange(type: 'from' | 'to')
    {
        switch (type) {
            case 'from':
                 this.minToDate = this.dateAdapter.fromModel(this.filters.from);
                break;
            case 'to':
                this.maxFromDate = this.dateAdapter.fromModel(this.filters.to);
                break;
        }
    }

    changeNavigateDate({next}: NgbDatepickerNavigateEvent, type: 'from' | 'to'): void
    {
        // if(!current && this.filters[type]) {
        //     next = this.dateAdapter.fromModel(this.filters[type]);
        // }
        const date = new NgbDate(next.year, next.month, 1);
        this.filters[type] = this.dateAdapter.toModel(date);
        // this.cd.detectChanges();
        this.dateSelectChange(type);
        this.emitFiltersChanged();
    }

    get dateFromPlaceholder(): string
    {
        return this.formatter.format(this.dateAdapter.fromModel(this.filters.from)) || this.FROM_PLACEHOLDER;
    }

    get dateToPlaceholder(): string
    {
        return this.formatter.format(this.dateAdapter.fromModel(this.filters.to)) || this.TO_PLACEHOLDER;
    }

    private setDefaultRangeDate(): void
    {
        this.minDate = MIN_DATE;
        this.minToDate = this.minDate;
        this.maxDate = MAX_DATE;
        this.maxFromDate = this.maxDate;
    }
}

export interface DateFilterInterface
{
    from: string;
    to: string;
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationService } from '../../services/location.service';
import { Subscription } from 'rxjs';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { fa } from 'cronstrue/dist/i18n/locales/fa';

@Component({
  selector: 'app-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.scss']
})
export class MapModalComponent implements OnInit, OnDestroy {
  @ViewChild(GoogleMap) gMap: GoogleMap;
  @ViewChild(MapMarker) mapMarker: MapMarker;
  @Input() location: google.maps.LatLngLiteral;
  locationServiceSubscription: Subscription;
  mapLoading: boolean;
  mapLoaded: boolean;
  zoom = 19;
  mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    zoomControlOptions: {
      position: 20.0,
    },
  };
  center: google.maps.LatLngLiteral;
  heightMap = '500px';
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
  };
  position: google.maps.LatLngLiteral;

  constructor(
    public activeModal: NgbActiveModal,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    console.log('location', this.location)
    this.center = this.location;
    this.position = this.location;
    this.initLoadMap();
  }

  ngOnDestroy(): void {
    this.locationServiceSubscription?.unsubscribe();
  }

  private initLoadMap(): void {
    this.mapLoading = true;
    this.locationServiceSubscription = this.locationService.loadMap().subscribe(async (loader) => {
      await loader.importLibrary('maps')
      this.mapLoaded = true;
      this.mapLoading = false;
    })
  }
}

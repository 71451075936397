import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { APP_CONFIG } from '../../../../config/app.server.config';

export type ApiDefinitionType = 'MARKETPLACE' | 'CUSTOMER' | 'ADMIN' | 'ADMIN_V2' | 'SELLER';
@Injectable()
export class AbstractRepository
{
    protected httpHeaders: HttpHeaders;
    protected httpParams: HttpParams;

    constructor(protected httpClient: HttpClient)
    {
        this.httpHeaders = new HttpHeaders();
        this.httpParams = new HttpParams();

        this.addHeader('Content-Type', 'application/json');
    }

    protected addHeader = (name: string, value: string): HttpHeaders => this.httpHeaders = this.httpHeaders.set(name, value);

    protected addHttpParam = (name: string, value: any): HttpParams => this.httpParams = this.httpParams.set(name, value);

    protected removeHttpParam = (name: string, value?): HttpParams => this.httpParams = this.httpParams.delete(name, value);

    protected buildApiUrl = (
        apiName: string,
        server: 'MAIN_SERVER'
            | 'PLATFORM_SERVER'
            | 'SELLER_SERVER'
            | 'ESB_SERVER'
            | 'DOCUMENT_SERVER' = 'PLATFORM_SERVER',
        type?: 'ADMIN' | 'MARKETPLACE' | 'CUSTOMER' | 'SELLER' | null
    ): string => `${APP_CONFIG[server]}${type ? APP_CONFIG.API[type] : '/'}${apiName}`;

    protected buildUrl = (
        apiName: string,
        apiDefinition: ApiDefinitionType = 'ADMIN'
    ): string => APP_CONFIG.PLATFORM_SERVER + APP_CONFIG.API[apiDefinition] + apiName;

    protected objectToFormData = (obj: Record<any, any>): FormData => {
       const formData = new FormData();

       for (const [key, value] of Object.entries(obj)) {
           switch (true) {
               case Array.isArray(value):
                   for (const item of value) {
                       formData.append(`${key}[${value.indexOf(item)}]`, item);
                   }
                   break;
               case typeof value === 'object' && value !== null && !!Object.keys(value).length:
                   for (const [k, v] of Object.entries(value)) {
                       formData.append(`${key}[${k}]`, (v as any));
                   }
                   break;
               default:
                   formData.append(key, value);
           }
       }

       return formData;
    };
    protected nestedObjectToFormData = (obj: Record<any, any>): FormData => {
        const formData = new FormData();
        this.buildFormData(formData, obj);
        return formData;
    };

    private buildFormData = (formData: FormData, obj, parentKey = '') => {
        if (Array.isArray(obj)) {
            obj.forEach((element, index) => this.buildFormData(formData, element, `${parentKey}[${index}]`));
        } else if (typeof obj === 'object' && obj !== null && !(obj instanceof File) && !!Object.keys(obj).length) {
            Object.keys(obj).forEach(key => this.buildFormData(
                formData,
                obj[key],
                parentKey ? `${parentKey}[${key}]` : key,
            ));
        } else {
            if (obj == null) return;

            const value = typeof obj === 'number' || typeof obj === 'boolean'
                ? obj.toString()
                : obj;
            formData.append(parentKey, value);
        }
    }
}

<div *ngIf="initialized" class="d-sm-flex align-items-center justify-content-between text-center text-sm-start m-2">
    <div class="main-pagination d-flex justify-content-start  align-items-center m-2">
        <div class="pagination-previous cursor-pointer">
            <button (click)="onChangePagination(getPrevDate)" class="btn btn-sm btn-primary" [disabled]="disabled">
                {{ (getPrevDate.from | bdate) + ' - ' + (getPrevDate.to | bdate) }}
            </button>
        </div>

        <div class="px-3 py-1">
            <span>{{ (selectedDate.from | bdate) + ' - ' + (selectedDate.to | bdate) }}</span>
        </div>

        <div class="pagination-next cursor-pointer">
            <button (click)="onChangePagination(getNextDate)"
                    [disabled]="isLastPage() || disabled"
                    class="btn btn-sm btn-primary"
            >
                {{ (getNextDate.from | bdate) + ' - ' + (getNextDate.to | bdate) }}
            </button>
        </div>
    </div>
</div>

<div *ngIf="hasError()"
     [@inOutAnimation]
     class="invalid-feedback d-block"
>

    <ng-content></ng-content>
    <!-- Required -->

    <ng-container *ngIf="control.errors.required">
        This field is required.
    </ng-container>

    <!-- Max number -->

    <ng-container *ngIf="control.errors.max">
        <!--    Value should be less than {{control.errors.max.max}}-->
        Maximum value is {{control.errors.max.max}}
    </ng-container>

    <!-- Min number -->

    <ng-container *ngIf="control.errors.min">
        <!--    Value should be greater than {{control.errors.min.min}}-->
        Minimum value is {{control.errors.min.min}}
    </ng-container>

    <!-- Min length -->

    <ng-container *ngIf="control.errors.minlength">
        Please enter at least {{ control.errors.minlength.requiredLength }} characters.
    </ng-container>

    <!-- Max length -->

    <ng-container *ngIf="control.errors.maxlength">
        Maximum length is {{ control.errors.maxlength.requiredLength }} characters.
    </ng-container>

    <!-- step -->

    <ng-container *ngIf="control.errors.step">
        The two nearest valid values by step are {{ control.errors.step.requiredStep }}.
    </ng-container>

    <!-- Email -->

    <ng-container *ngIf="control.errors.email">
        Please enter a valid email address.
    </ng-container>

    <!-- Addresses -->

    <ng-container *ngIf="control.errors.wrongPin">
        Wrong PIN.
    </ng-container>

    <ng-container *ngIf="control.errors.pinNotBelongState">
        State in PIN and in Address is not the same
    </ng-container>

    <ng-container *ngIf="control.errors.gstPinNotBelongState">
        The PIN should be valid and match the state from your address and GST number
    </ng-container>

    <ng-container *ngIf="control.errors.negativeBalance">
        boodmo Credits can’t be a negative value
    </ng-container>

    <ng-container *ngIf="control.errors.gstInvalid"
    >
        <!--    class="invalid-feedback d-block"-->
        Wrong GST.
    </ng-container>
    <ng-container *ngIf="control.errors.gstNotUnique"
    >
        <!--    class="invalid-feedback d-block"-->
        GST number is not unique.
    </ng-container>
    <ng-container *ngIf="control.errors.gstNotBelongState"
    >
        <!--    class="invalid-feedback d-block"-->
        GST number does not match the state
    </ng-container>

    <ng-container *ngIf="control.errors.wrongCompanyName">
        Please use at least 2 Latin letters or numbers, but not less than 3 characters in total. You can also use the following symbols .,-/&'
    </ng-container>

    <ng-container *ngIf="control.errors?.wrongForVolume">
        {{ control.errors?.wrongForVolume }}
    </ng-container>

    <!-- Pattern -->

    <ng-container *ngIf="control.errors.pattern">
        <ng-container *ngIf="isPattern(validators.EMAIL)">
            Please enter a valid email address.
        </ng-container>
        <ng-container *ngIf="isPattern(validators.NUMBER)">
            Please enter only numbers.
        </ng-container>
        <ng-container *ngIf="isPattern(validators.NUMBER_FLOAT)">
            Please enter valid number. Example: 1.23
        </ng-container>
        <ng-container *ngIf="isPattern(validators.NAME)">
            Please, enter only Latin characters, spaces, ".", "'" or "-" symbols.
        </ng-container>
        <ng-container *ngIf="isPattern(validators.CUSTOMER_NAME)">
            Please enter Latin letters with no numbers and no symbols.
        </ng-container>
        <ng-container *ngIf="isPattern(validators.PHONE)">
            Please enter valid phone number.
        </ng-container>
        <ng-container *ngIf="isPattern(validators.ADDRESS)">
            Address is not valid.
        </ng-container>
        <ng-container *ngIf="isPattern(validators.COMPANY_NAME)">
            Company name is not valid.
        </ng-container>
        <ng-container *ngIf="isPattern(validators.PIN)">
            Please enter valid PIN.
        </ng-container>
        <ng-container *ngIf="isPattern(validators.VIN)">
            Please enter valid VIN.
        </ng-container>
        <ng-container *ngIf="isPattern(validators.GST)">
            Please enter valid GST.
        </ng-container>
        <ng-container *ngIf="isPattern(validators.CITY)">
            Please, use English characters.
        </ng-container>
        <ng-container *ngIf="isPattern(validators.UUID)">
            Please enter valid UUID.
        </ng-container>
        <ng-container *ngIf="isPattern(validators.TRACK_NUMBER)">
            Please enter valid track number.
        </ng-container>
    </ng-container>
</div>

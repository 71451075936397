export class DateUtil
{
    static addDays = (date: Date, days: number, resetTimeByTimeZone = false): Date => {
        const result = new Date(date);
        result.setUTCDate(date.getUTCDate() + days);
        return resetTimeByTimeZone
            ? new Date(result.setHours(0, 0, 0, 0) - result.getTimezoneOffset() * 60000)
            : result;
    };

    static convertStringToDate = (dateString?: string): Date | null => {
        if (!dateString) {
            return null;
        }
        return new Date((/Z/.test(dateString) || dateString.includes('+00:00'))
            ? dateString
            : `${dateString.replace(/\s/, 'T')}Z`);
    };

    static convertTimestampToDate = (timestamp: number): Date | null => timestamp ? new Date(timestamp * 1000) : null;

    static convertDateToInputFormat = (date: Date): string | null =>
        date?.toLocaleDateString('en-GB')?.replace(/(\w+).(\w+).(\w+)/i, '$3-$2-$1') ?? null;

    static convertDateToDbFormat = (date: Date): string => `${date.toISOString().slice(0, 19)}Z`;

    static convertStringToDbFormat = (date: string, endDay?: boolean) => {
        date = date.replace(/\s/, 'T');
        if(date.length === 10) {
            return `${date}T${endDay ? '23:59:59' : '00:00:00'}Z`;
        }
        return `${date}Z`;
    };

    static datesComparison = (
        operator: 'same' | 'before' | 'after' | 'sameOrBefore' | 'sameOrAfter',
        firstDate: Date,
        secondDate: Date,
        ignoreTime = true,
    ): boolean => {
        const normalizedFirstDate =  ignoreTime ? new Date(firstDate).setHours(0,0,0,0) : firstDate;
        const normalizedSecondDate = ignoreTime ? new Date(secondDate).setHours(0,0,0,0) : secondDate;

        switch (operator) {
            case 'same':
                return normalizedFirstDate === normalizedSecondDate;
            case 'before':
                return normalizedFirstDate < normalizedSecondDate;
            case 'after':
                return normalizedFirstDate > normalizedSecondDate;
            case 'sameOrBefore':
                return normalizedFirstDate <= normalizedSecondDate;
            case 'sameOrAfter':
                return normalizedFirstDate >= normalizedSecondDate;
        }
    };

    static getTimeUnits(seconds: number): string {
        let str = '';

        if (!seconds || seconds <= 0) {
            return '-/-';
        }

        if (seconds >= 3600) {
            str += ` ${Math.trunc(seconds / 3600)} h`;
            seconds = seconds % 3600;
        }
        if (seconds > 60 && seconds < 3600) {
            str += ` ${Math.trunc(seconds / 60)} min`;
            seconds = seconds % 60;
        }

        if (seconds > 0) {
            str += ` ${Math.trunc(seconds)} s`;
        }

        return str.trim();
    }

    static setTimeToDate = (date: Date, endDay?: boolean): Date => new Date(date.setHours(endDay ? 23 : 0, endDay ? 59 : 0, endDay ? 59 : 0, endDay ? 999 : 0));

    static dateToSeconds = (date: Date, config?: {
        endDay?: boolean;
        useTime?: boolean;
    }): number => Math.floor((config?.useTime
            ? date
            : DateUtil.setTimeToDate(date, config?.endDay)
    ).getTime() / 1000);

    static getDaysDifference = (firstDate: Date, secondDate: Date): number =>
        Math.ceil(Math.abs((secondDate.setHours(0,0,0,0) - firstDate.setHours(0,0,0,0))) / (1000 * 60 * 60 * 24));

    static toDateTime = (value: Date | string | number, endDay?: boolean) => {
        if (!value) {
            return null;
        }
        const fixedValue = DateUtil.setTimeToDate(new Date(value), endDay);
        return fixedValue.toISOString().slice(0,19).replace(/T/, ' ');
    };

    static getDate(date: number | string | Date): Date
    {
        switch (true) {
            case typeof date === 'string':
                return DateUtil.convertStringToDate(date as string);
            case date instanceof Date:
                return date as Date;
            case typeof date === 'number':
                return DateUtil.convertTimestampToDate(date as number);
            case !date:
                return null;
        }
    }

    static getDays = (year, month) => new Date(year, month, 0).getDate();
}

<div class="row row-cols g-2" *ngIf="filters">
    <div class="mb-3 col col-12 col-sm-6 mxw-200">
        <div class="input-group input-group-sm">
            <input type="text" class="form-control"
                   aria-label="date from" aria-describedby="date from"
                   readonly
                   [placeholder]="dateFromPlaceholder"
                   (click)="dateFrom.toggle()"
                   [(ngModel)]="filters.from"
                   ngbDatepicker #dateFrom="ngbDatepicker"
                   [minDate]="minDate"
                   [maxDate]="maxFromDate"
                   [footerTemplate]="datepickerFromFooter"
                   [datepickerClass]="'datepickerWithoutDays'"
                   (navigate)="changeNavigateDate($event, 'from')"
                   (keyup.enter)="applyFiltersEmit()"
            >

            <button class="btn btn-outline-primary" type="button" id="date_from" (click)="dateFrom.toggle()">
                <fa-icon icon="calendar-alt"></fa-icon>
            </button>

            <ng-template #datepickerFromFooter>
                <hr class="my-0">
<!--                <button class="btn btn-success btn-sm m-2 float-start" type="button" (click)="applyFiltersEmit();dateFrom.close()">Apply</button>-->
                <button class="btn btn-secondary btn-sm m-2 float-end" (click)="filters.from = null;dateSelectChange('from');emitFiltersChanged();dateFrom.close()">Clear</button>
            </ng-template>
        </div>
    </div>
    <div class="mb-3 col col-12 col-sm-6 mxw-200">
        <div class="input-group input-group-sm">
            <input type="text" class="form-control"
                   aria-label="date to" aria-describedby="date to"
                   readonly
                   [placeholder]="dateToPlaceholder"
                   (click)="dateTo.toggle()"
                   [(ngModel)]="filters.to"
                   ngbDatepicker #dateTo="ngbDatepicker"
                   [minDate]="minToDate"
                   [maxDate]="maxDate"
                   [footerTemplate]="datepickerToFooter"
                   (navigate)="changeNavigateDate($event, 'to')"
                   [datepickerClass]="'datepickerWithoutDays'"
                   (keyup.enter)="applyFiltersEmit()">
            <button class="btn btn-outline-primary" type="button" id="date_to" (click)="dateTo.toggle()">
                <fa-icon icon="calendar-alt"></fa-icon>
            </button>

            <ng-template #datepickerToFooter>
                <hr class="my-0">
<!--                <button class="btn btn-success btn-sm m-2 float-start" type="button" (click)="applyFiltersEmit();dateTo.close()">Apply</button>-->
                <button class="btn btn-secondary btn-sm m-2 float-end" (click)="filters.to = null;dateSelectChange('to');emitFiltersChanged();dateTo.close()">Clear</button>
            </ng-template>
        </div>
    </div>
</div>

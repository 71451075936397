import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bStatus'
})
export class BStatusPipe implements PipeTransform
{
    transform(value: string, type?: 'salesItem' | string): string
    {
        let statusMap: Record<string, string> = {};
        switch (type) {
            case 'salesItem':
                statusMap = {pending : 'MP Cancel Requested'};
                break;
        }
        // if (!value) {return null;}
        return statusMap[value?.toLowerCase()] ?? value;
    }
}

import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DateUtil } from '../../../../../core/application/utils/date/date.util';
import { FilterQueryBuilder } from '../../../../../core/application/utils/query-builder/filter-query-builder';
import { CustomDateParserFormatter } from '../../../../../core/application/utils/date/date.parser';
import { UiFilterType } from "../../../../../crm/application/components/unpaid-orders/ui-filter/ui-filter.type";

export interface FindInListInterface
{
    id: any;
    name: string;
    disabled?: boolean;
}
export interface FindInListWithGroupsInterface extends FindInListInterface
{
    group: FindInListInterface[];
}

export interface FindInListWithGroupsInterface extends FindInListInterface
{
    group: FindInListInterface[];
}

export interface ConfigInterface
{
    value: any;
    applied: boolean;
    name: string;
    title?: string;
    initValue?: any;
    outside?: boolean;
}

export interface FilterConfigInterface extends ConfigInterface
{
    type: 'text' | 'dateRange' | 'select' | 'numberRange' | 'ngSelect' | 'positiveNumber';
    uiType?: UiFilterType;
    usePice?: boolean;
    dateWithTime?: boolean;
    rangePrefix?: string[];
}

export interface ColumnInterface
{
    filter?: FilterConfigInterface;
    sort?: ConfigInterface;
    class?: string;
}

@Component({
    selector: 'app-th',
    templateUrl: './th.component.html',
    styleUrls: ['./th.component.scss'],
    providers: [
        CustomDateParserFormatter
    ]
})
export class ThComponent implements OnInit, DoCheck
{

    @Input() readonly colKey: string;
    @Input() readonly config: ColumnInterface;
    @Input() readonly dateInSeconds: boolean;
    @Input() readonly dateWithTime: boolean;
    @Input() readonly toStartDay: boolean;
    // @Input() readonly toEndDay: boolean;
    @Input() readonly moneyInPennies: boolean;
    @Input() readonly isMoney: boolean;
    @Input() readonly queryBuilder: FilterQueryBuilder;
    @Input() readonly inputWidth: string;
    @Input() readonly maxWidthTruncate?: number;
    @Input() readonly wrapOptions?: string;
    @Input() readonly multiselect?: boolean;
    @Input() name?: string;
    @Input() readonly options?: (FindInListInterface | string)[];
    @Input() readonly rangeSuffix?: string[] = ['_from', '_to'];
    @Input() readonly placeholder?: string = '';
    @Input() readonly useMaxDate?: Date | null = null;
    @Input() readonly showAllOption: boolean;
    @Input() readonly rangeHasNegativeValue: boolean;

    @Output() readonly update = new EventEmitter<null>();
    @Output() readonly sort = new EventEmitter<ConfigInterface>();
    @ViewChild('dRef', {read: NgbDropdown, static: true}) dRef: NgbDropdown;

    public minDate: NgbDateStruct;
    public minToDate: NgbDateStruct;
    public maxDate: NgbDateStruct;
    public maxFromDate: NgbDateStruct;
    public currentDate: string;

    public valueFrom: string;
    public valueTo: string;
    public canApplyFilter: boolean;
    public showAllOptionValue: any;

    constructor(
      public formatter: CustomDateParserFormatter,
      private cd: ChangeDetectorRef,
    )
    {}

    static filterIsRange(config: ColumnInterface): boolean
    {
        return config?.filter?.type === 'dateRange' || config?.filter?.type === 'numberRange';
    }

    static fixDateFilterValue(value, rangeSuffix?: string, dateWithTime?: boolean, dateInSeconds?: boolean){
        let fixedValue;
            fixedValue = new Date(value);
            if (dateWithTime) {
                if (/from/.test(rangeSuffix.toLowerCase())) {
                    fixedValue = DateUtil.setTimeToDate(fixedValue);
                }
                if (/to/.test(rangeSuffix.toLowerCase())) {
                    fixedValue = DateUtil.setTimeToDate(fixedValue, true);
                }
            }
            fixedValue = fixedValue.toISOString().slice(0, (dateWithTime === undefined || dateWithTime) ? 19 : 10).replace(/T/, ' ');

            if (dateInSeconds) {
                fixedValue = new Date(DateUtil.convertStringToDbFormat(fixedValue)).getTime() / 1000;
            }
        return fixedValue;
    }

    get filterIsRange(): boolean
    {
        return ThComponent.filterIsRange(this.config);
    }

    ngOnInit(): void
    {
        this.setShowAllOptionValue();
        this.setDefaultRangeDate();
        this.name = this.name || this.config.filter?.title;
    }

    ngDoCheck(): void {
        this.setCanApplyFilter();
    }

    setShowAllOptionValue(): void {
        this.showAllOptionValue = this.config?.filter?.value;
    }

    setDefaultRangeDate(): void {
        if (this.config?.filter?.type === 'dateRange') {
            this.minDate = this.formatter.parse('1970-01-01', true);
            this.minToDate = this.minDate;
            const date = new Date();
            this.maxDate = this.formatter.parse((this.useMaxDate
                    ? this.useMaxDate
                    : this.useMaxDate !== null
                        ? date
                        : new Date(date.setFullYear(2999))
            ).toISOString().slice(0, 10), true);
            this.maxFromDate = this.maxDate;
            this.currentDate = new Date().toISOString().slice(0, 10);
        }
    }

    toggleSort(item: ConfigInterface, value: boolean, dropdown?: NgbDropdown): void
    {
        item.applied = item.value === value ? !item.applied : true;

        if (item.applied) {
            item.value = value;
            this.queryBuilder.handleSort(item.name, item.value ? 'desc' : 'asc');
        } else {
            item.value = null;
            this.queryBuilder.clearSort();
        }

        this.update.emit();
        this.sort.emit(item);
        dropdown?.toggle();
    }

    setCanApplyFilter(): void
    {
        this.canApplyFilter = (!this.filterIsRange ||
          !this.valueFrom ||
          !this.valueTo ||
          (this.valueFrom && this.valueTo && this.valueFrom <= this.valueTo));
        this.cd.detectChanges();
    }

    applyFilter(dropdown: NgbDropdown): void
    {
        dropdown.toggle();
        this.setAppliedFilter();
        this.config.filter.applied = this.filterIsRange ? (!!this.valueFrom || !!this.valueTo) : !!this.config?.filter.value;
        this.update.emit();
        this.queryBuilder.applyFilters.next(true);
    }

    clearFilter(dropdown?: NgbDropdown): void
    {
        // const updateNeeded = this.config?.filter?.applied;
        dropdown?.toggle();
        if (this.config.filter) {
            this.setDefaultRangeDate();

            if (this.filterIsRange) {
                this.valueFrom = '';
                this.valueTo = '';
                this.onChangeFilter(this.config?.filter.name, '', {rangeSuffix: this.rangeSuffix[0]});
                this.onChangeFilter(this.config?.filter.name, '', {rangeSuffix: this.rangeSuffix[1]});
            } else {
                this.config.filter.value = this.showAllOptionValue;
                this.onChangeFilter(this.config?.filter.name, this.config.filter.value);
            }

            if (!this.config.filter.applied) {
                return;
            }
            this.config.filter.applied = false;
        }

        // if (updateNeeded) {
            this.update.emit();
            this.queryBuilder.applyFilters.next(true);
        // }
    }

    onChangeFilter(filterKey: string, value: any, options?: {isDate?: boolean; rangeSuffix?: string; multiple?: boolean}): void
    {
        if(this.config?.filter?.type === 'text' && this.config?.filter?.value) {
            this.config.filter.value = value.trim();
        }

        let fixedValue;
        if (value && options?.isDate) {
            fixedValue = ThComponent.fixDateFilterValue(value, options?.rangeSuffix, this.dateWithTime, this.dateInSeconds);
        }

        if (this.moneyInPennies) {
            fixedValue = value || value === 0 ? Math.round(value * 100) : value;
        }

        this.queryBuilder.handleFilter(filterKey, fixedValue ?? (typeof value === 'string' ? value?.trim() : value), {
            moneyInPennies: this.moneyInPennies,
            isMoney: this.isMoney,
            dateInSeconds: this.dateInSeconds,
            dateWithTime: this.dateWithTime,
            isDate: options?.isDate,
            rangeSuffix: options?.rangeSuffix,
            multiple: options?.multiple,
        });
        if (this.queryBuilder.getPage()) {
            this.queryBuilder.changePageNum(1);
        }

        this.cd.detectChanges();
    }

    setRangeFilter(isDate = false): void {
        this.onChangeFilter(this.config.filter.name, this.valueFrom, {isDate, rangeSuffix: this.rangeSuffix[0]});
        this.onChangeFilter(this.config.filter.name, this.valueTo, {isDate, rangeSuffix: this.rangeSuffix[1]});
    }

    setAppliedFilter(): void {
        switch (this.config.filter.type) {
            case 'dateRange':
                this.setRangeFilter(true);
                break;
            case 'numberRange':
                this.setRangeFilter();
                break;
            default:
                this.onChangeFilter(this.config.filter.name, this.config.filter.value, { multiple: !this.multiselect });
        }
    }

    dateSelectChange(type: string) {
        switch (type) {
            case 'from':
                this.minToDate = this.formatter.parse(this.valueFrom, true);
                break;
            case 'to':
                this.maxFromDate = this.formatter.parse(this.valueTo, true);
                break;
        }
    }
}

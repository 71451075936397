<div class="row mb-3">
    <label class="pb-0 col-4 col-form-label" [for]="id">{{label}}:</label>
    <form class="col-8"
          [ngClass]="{'gap-2 d-flex': isRange}"
          (ngSubmit)="form.form.valid && changeFilter.emit( type==='money'?{from: valueFrom, to: valueTo}:model); applyFilter.emit()"
          novalidate
          #form="ngForm"
    >
        <ng-select
            *ngIf="type==='ngSelect'"
            #ngSelect
            class="ng-select-sm"
            name="model"
            [id]="id"
            [disabled]="disabled || loading"
            placeholder="All"
            (change)="changeFilter.emit($event);"
            [loading]="loading"
            [items]="items"
            [multiple]="multiple"
            [(ngModel)]="model"
            bindLabel="name"
        >
<!--            <ng-template ng-header-tmp>-->
<!--                <input class="filters-checkbox"-->
<!--                       type="checkbox"-->
<!--                       [id]="id+'SelectAll'"-->
<!--                       [attr.data-type-name]="id"-->
<!--                       [hidden]="true"-->
<!--                />-->
<!--                <label class="filters-checkbox__label"-->
<!--                       [for]="id+'SelectAll'"-->
<!--                >-->
<!--                    Select All-->
<!--                </label>-->
<!--            </ng-template>-->

            <ng-template ng-header-tmp>
                <button class="btn btn-sm btn-light w-100 py-0 px-1 text-start"
                        (click)="ngSelect.clearModel(); ngSelect.close();"
                >Select All</button>
            </ng-template>

            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngFor="let item of items | slice:0:1">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </div>
                <div class="ng-value" *ngIf="items.length > 1">
                    <span class="ng-value-label">{{items.length - 1}} more...</span>
                </div>
            </ng-template>
        </ng-select>

        <select *ngIf="type === 'select'"
                [id]="id"
                name="model"
                class="form-select form-select-sm"
                [disabled]="loading || disabled"
                [(ngModel)]="model"
                (ngModelChange)="changeFilter.emit($event)"
                (keyup.enter)="applyFilter.emit()"
        >
            <option [ngValue]="showAllOptionValue">All</option>
            <option *ngFor="let option of items" [ngValue]="option.id">{{option.name}}</option>
            <ng-content select=".options"></ng-content>
        </select>

        <input *ngIf="type === 'text'" type="text"
               [id]="id"
               name="model"
               [pattern]="pattern"
               class="form-control form-control-sm"
               [placeholder]="placeholder || label"
               [(ngModel)]="model"
               (ngModelChange)="form.form.valid && changeFilter.emit($event)"
               [disabled]="disabled"
               [class.is-invalid]="form.form.controls.model?.invalid && form?.submitted"
               [class.is-valid]="!form.form.controls.model?.invalid && form?.submitted"
        >
        <app-form-control-error
            *ngIf="form.form.controls.model"
            [control]="form.form.controls.model"
            [formSubmit]="form.submitted"
        ></app-form-control-error>

        <ng-container *ngIf="type === 'money'">
        <div class="position-relative w-100" >
            <div class="d-flex gap-1">
            <input type="number" [id]="id"
                   class="form-control form-control-sm"
                   placeholder="from"
                   name="from"
                   [appNumeric]="2"
                   [min]="0"
                   [max]="(+valueTo > 0) ? valueTo : null"
                   [pattern]="'^\\d+((\.|\,)\\d{0,2})?$'"
                   [(ngModel)]="valueFrom"
                   (ngModelChange)="form.form.valid && changeFilter.emit({from: valueFrom, to: valueTo});detectChanges()"
                   [disabled]="disabled"
                   [ngClass]="getValidationClass('from', form?.form)"
            >
            <input type="number"
                   class="form-control form-control-sm"
                   placeholder="to"
                   name="to"
                   [appNumeric]="2"
                   [min]="(+valueFrom > 0) ? valueFrom : 0"
                   [step]="0.01"
                   [pattern]="'^\\d+((\.|\,)\\d{0,2})?$'"
                   [(ngModel)]="valueTo"
                   (ngModelChange)="form.form.valid && changeFilter.emit({from: valueFrom, to: valueTo});detectChanges()"
                   [disabled]="disabled"
                   [ngClass]="getValidationClass('to', form?.form)"
            >
            </div>
            <div class="invalid-feedback d-block"
                 [class.d-block]="!form?.form?.valid"
                 *ngIf="!canApplyFilter">"To" must be greater than "From"
            </div>
            <div class="invalid-feedback d-block"
                 [class.d-block]="!form?.form?.valid"
                 *ngIf="form?.form?.getError('pattern', 'from') || form?.form?.getError('pattern', 'to')">
                The values must be in the correct format, like: "25.23"</div>
            <input type="submit" hidden [disabled]="!canApplyFilter || form?.form?.invalid">
        </div>
        </ng-container>
    </form>
</div>

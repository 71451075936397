import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { PaginatePipeArgs } from 'ngx-pagination';
import { Subscription } from 'rxjs';
import { DateUtil } from '../../../../core/application/utils/date/date.util';
import { GridHelper } from '../../../../core/application/utils/grid/grid-helper';
import { FilterQueryBuilder } from '../../../../core/application/utils/query-builder/filter-query-builder';
import { UiFilter } from '../../../../crm/application/components/unpaid-orders/ui-filter/ui-filter';
import { UiFilterConfigInterface } from '../../../../crm/application/components/unpaid-orders/ui-filter/ui-filter-config.interface';
import { EventHistoryInterface } from '../../../domain/models/event-history.interface';
import { ToastrService } from '../../services/toastr.service';
import { ColumnInterface, ConfigInterface, FindInListInterface } from '../tables/th/th.component';

@Component({
    selector: 'app-event-history-grid',
    templateUrl: './event-history-grid.component.html',
    styleUrls: ['./event-history-grid.component.scss']
})
export class EventHistoryGridComponent implements OnDestroy, OnChanges
{

    @Input() eventHistory: EventHistoryInterface[];

    readonly cols: {[key: string]: ColumnInterface} = {
        eventName: {
            filter: {value: '', applied: false, name: 'eventName', type: 'ngSelect', title: 'Event'},
            sort: {name: 'eventName', value: null, applied: false},
        },
        author: {
            filter: {value: '', applied: false, name: 'author', type: 'text', title: 'Author'},
            sort: {name: 'author', value: null, applied: false},
        },
        date: {
            filter: { value: '', applied: false, name: 'date', type: 'dateRange', title: 'Date'},
            sort: { name: 'date', value: true, applied: true },
        },
    };

    eventList: FindInListInterface[];
    queryBuilder: FilterQueryBuilder;
    tableConfig: PaginatePipeArgs = {
        currentPage: 1,
        itemsPerPage: 10,
        id: 'event_history_grid'
    };

    getDate = DateUtil.getDate;
    private filter: UiFilter<EventHistoryInterface>;
    private filterChangesSubscription: Subscription;

    private readonly filterConfig: UiFilterConfigInterface[] = [
        { fieldName: 'eventName', type: 'multipleSelect', filterField: 'eventName' },
        { fieldName: 'author', type: 'text', filterField: 'author' },
        { fieldName: 'date', type: 'dateFrom', filterField: 'date_from' },
        { fieldName: 'date', type: 'dateTo', filterField: 'date_to' },
    ];
    constructor(
        public cd: ChangeDetectorRef,
        public toastrService: ToastrService,
    )
    {
        this.queryBuilder = new FilterQueryBuilder({
            filterSet: [
                'eventName',
                'author',
                'date_from',
                'date_to',
            ],
            page: 1,
            limit: 10,
        });
    }

    ngOnChanges(changes: SimpleChanges): void
    {
        if (changes.eventHistory.currentValue !== changes.eventHistory.previousValue) {
            this.createEventList(changes.eventHistory.currentValue);
            const sortCol = Object.values(this.cols).find((c) => c?.sort?.applied !== null)?.sort;
            if (sortCol) {
                sortCol.applied = false;
                sortCol.value = null;
            }
            GridHelper.ClearAllFilters(this.cols);
            this.queryBuilder.clearFilters();
            this.queryBuilder.clearSort();
        }
        this.createFilter(changes.eventHistory.currentValue);
    }

    ngOnDestroy(): void
    {
        this.filterChangesSubscription?.unsubscribe();
    }

    sort = (sortCol: ConfigInterface): void => {
        this.filter.sort(sortCol);
        this.queryBuilder.changePageNum(1);
        GridHelper.ClearSorting(this.cols, sortCol);
    };

    filterData(items?: EventHistoryInterface[])
    {
        const sortCol = Object.values(this.cols).find((c) => c.sort?.applied)?.sort;

        this.filter.filter(items);

        if (sortCol) {
            this.filter.sort(sortCol);
            this.queryBuilder.changePageNum(1);
        }
    }

    private createFilter(eventHistory): void
    {
        this.filter = new UiFilter(eventHistory, this.filterConfig, this.queryBuilder);
        this.filterChangesSubscription = this.filter.filterChanged$.subscribe((v) => this.eventHistory = v);
        const appliedSortingKey = Object.keys(this.cols).find((key) => this.cols[key].sort.applied);
        if (appliedSortingKey) {
            this.filter.sort(this.cols[appliedSortingKey].sort);
        }
    }

    private createEventList(historyList: EventHistoryInterface[]): void
    {
        const eventMap = {};
        for (const historyItem of historyList) {
            if (!eventMap[historyItem.eventName]) {
                eventMap[historyItem.eventName] = {
                    id: historyItem.eventName,
                    name: historyItem.eventName
                };
            }
        }
        this.eventList = Object.values(eventMap);
    }
}

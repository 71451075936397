import {
    AfterContentInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Optional,
    Output,
    Renderer2,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appFileUploader]',
})
export class FileUploaderDirective implements AfterContentInit, OnDestroy
{

    @Input() readonly multiple: boolean = false;
    @Input() readonly openElement: HTMLElement;
    @Input() readonly deleteBtn: HTMLElement;
    @Input() readonly extensions: string;
    @Input() readonly limit: number;
    @Input() readonly maxSize: SizeRestriction;
    @Input() readonly minSize: SizeRestriction;
    @Input() readonly strictSize: SizeRestriction;
    @Input() readonly readFile: boolean;
    @Input() readonly strictFileType: string;
    @Output() readonly filesChange = new EventEmitter<File | File[]>();
    @Output() readonly readFileDone = new EventEmitter<ArrayBuffer | string | (ArrayBuffer | string)[]>();
    @Output() readonly stateChanged = new EventEmitter<boolean>();
    @Output() readonly fileExtensionValidity = new EventEmitter<boolean>();

    private files: File | File[];
    private fileInput: HTMLInputElement;
    private deleteClickedListener: () => void;
    private fileChangesListener: () => void;
    private openElementClickedListener: () => void;

    constructor(private _renderer: Renderer2, @Optional() private _control: NgControl, private _el: ElementRef)
    { }

    ngAfterContentInit(): void
    {
        this.fileInput = this._renderer.createElement('input');
        this._renderer.setAttribute(this.fileInput, 'type', 'file');
        this._renderer.setAttribute(this.fileInput, 'accept', this.extensions);
        this._renderer.setAttribute(this.fileInput, 'multiple', this.multiple.toString());
        this._renderer.setStyle(this.fileInput, 'display', 'none');
        this._renderer.appendChild(this._el.nativeElement, this.fileInput);
        this.subscribeToFileChanges();
        this.subscribeToFOpenElementClicked();
        if (this.deleteBtn) {
            this.subscribeToFDeleteElementClicked();
        }
    }

    ngOnDestroy(): void
    {
        if (this.fileChangesListener) {
            this.fileChangesListener();
        }
        if (this.openElementClickedListener) {
            this.openElementClickedListener();
        }
        if (this.deleteClickedListener) {
            this.deleteClickedListener();
        }
    }

    private checkImageSize(img: HTMLImageElement): boolean
    {
        let invalid: boolean;

        if (this.strictSize) {
            invalid = img.naturalWidth !== this.strictSize.width || img.naturalHeight !== this.strictSize.height;
        } else if (this.minSize) {
            invalid = img.naturalWidth < this.minSize.width || img.naturalHeight < this.minSize.height;
        } else if (this.maxSize) {
            invalid = img.naturalWidth > this.maxSize.width || img.naturalHeight > this.maxSize.height;
        }

        return invalid;
    }

    private subscribeToFileChanges(): void
    {
        this.fileChangesListener = this._renderer.listen(this.fileInput, 'change', (event) => {
            const files: File | File[] = this.multiple
                ? Array.from(event.target.files).slice(0, this.limit)
                : event.target.files.item(0);

            if (this.strictFileType) {
                const regex = new RegExp(this.strictFileType, 'i');
                const validType = files instanceof Array
                    ? files.every((f) => f.name.match(regex))
                    : !!(files as File).name.match(regex)?.length;

                if (!validType) {
                    event.preventDefault();
                    this.fileExtensionValidity.emit(false);
                    return;
                } else {
                    this.fileExtensionValidity.emit(true);
                }
            }

            if (!files && this.files) {
                if (this._control) {
                    this._control.valueAccessor.writeValue(
                        this.files instanceof Array ? this.files.map((f) => f.name) : (this.files as File).name,
                    );
                }
                event.preventDefault();
                return;
            }

            this.files = files;
            if (this._control) {
                this._control.valueAccessor.writeValue(files);
                this._control.control.patchValue(files);
            }
            this.filesChange.emit(files);

            if (this.readFile) {
                const promises: Promise<string | ArrayBuffer | null>[] =
                    files instanceof Array ? files.map((f) => this.readImage(f)) : [this.readImage(files)];

                Promise.all(promises).then((values) => {
                    const invalid = values.some((i) => i === null);
                    const images = values.filter((i) => i !== null);

                    this.readFileDone.emit(images.length ? images : null);
                    this.stateChanged.emit(!invalid);
                    if (!images.length) {
                        this.filesChange.emit(null);
                    }
                });
            }

            this.fileInput.value = null;
        });
    }

    private subscribeToFOpenElementClicked(): void
    {
        this.openElementClickedListener = this._renderer.listen(this.openElement, 'click', () => {
            this.fileInput.click();
        });
    }

    private subscribeToFDeleteElementClicked(): void
    {
        this.deleteClickedListener = this._renderer.listen(this.deleteBtn, 'click', () => {
            if (this._control) {
                this._control.valueAccessor.writeValue(null);
                this._control.control.patchValue(null);
            }
            this.fileInput.value = null;
        });
    }

    private readImage(file: File): Promise<string | ArrayBuffer | null>
    {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                const img = new Image();
                img.onload = () => {
                    const invalid = this.checkImageSize(img);

                    resolve(invalid ? null : reader.result);
                };
                img.src = reader.result as string;
            };

            reader.onerror = () => {
                reject(reader);
            };
        });
    }

}

export interface SizeRestriction
{
    width: number;
    height: number;
}

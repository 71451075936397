import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-inline-loader',
    templateUrl: './inline-loader.component.html',
})
export class InlineLoaderComponent
{
    /**
     * @loaderType 'circle', 'satellite', 'spin-dash', 'pixel', 'atom'
     */
    @Input() loaderType: 'circle' | 'satellite' | 'spin-dash' | 'pixel' | 'atom' = 'atom';
    @Input() loaderClasses = '';
    @Input() loaderClassesWarp = 'px-2';

    public getClasses(): string
    {
        return `${this.loaderClasses} ${this.getLoaderClass()}`;
    }

    private getLoaderClass(): string
    {
        return `loader-${this.loaderType}`;
    }
}

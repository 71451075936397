<div class="row row-cols g-2"
     [ngClass]="class"
     *ngIf="filters"
>
    <div class="mb-3 col col-12 col-sm-6 mxw-200"
         [ngClass]="{'d-sm-flex gap-2': useTime}"
    >
        <label *ngIf="showLabels" class="form-label">{{labelPrefix}} from</label>
        <div class="input-group input-group-sm"
             [ngClass]="{'w-184' : useTime}"
        >
            <input type="text" class="form-control" [placeholder]="dateFromPlaceholder"
                   aria-label="date from" aria-describedby="date from"
                   readonly required
                   [disabled]="disabled"
                   (click)="dateFrom.toggle()"
                   [(ngModel)]="filters.from"
                   (ngModelChange)="emitFiltersChanged()"
                   (dateSelect)="dateSelectChange('from')"
                   ngbDatepicker #dateFrom="ngbDatepicker"
                   [minDate]="minDate"
                   [maxDate]="maxFromDate"
                   [footerTemplate]="datepickerFromFooter"
                   (keyup.enter)="applyFiltersEmit()">
            <button
                class="btn btn-outline-primary"
                type="button"
                id="date_from"
                (click)="dateFrom.toggle()"
                [disabled]="disabled"
            >
                <fa-icon icon="calendar-alt"></fa-icon>
            </button>

            <ng-template #datepickerFromFooter>
                <hr class="my-0">
<!--                <button class="btn btn-primary btn-sm m-2 float-start" (click)="filters.from = currentDate;dateFrom.close()">Today</button>-->
                <button class="btn btn-secondary btn-sm m-2 float-end" (click)="clearFrom();emitFiltersChanged();dateFrom.close()">Clear</button>
            </ng-template>
        </div>

        <ngb-timepicker *ngIf="useTime"
                        [(ngModel)]="time.from"
                        (ngModelChange)="emitFiltersChanged()"
                        [spinners]="false"
                        [disabled]="disabled || !filters?.from"
                        [ngbTooltip]="!filters?.from ? 'Please select date first' : 'Select time'"
                        class="form-control-sm p-0"
        />

    </div>
    <div class="mb-3 col col-12 col-sm-6 mxw-200"
         [ngClass]="{'d-sm-flex gap-2': useTime}"
    >
        <label *ngIf="showLabels" class="form-label">{{labelPrefix}} to</label>
        <div class="input-group input-group-sm"
             [ngClass]="{'w-184' : useTime}"
        >
            <input type="text" class="form-control" [placeholder]="dateToPlaceholder"
                   aria-label="date to" aria-describedby="date to"
                   readonly required
                   [disabled]="disabled"
                   (click)="dateTo.toggle()"
                   [(ngModel)]="filters.to"
                   (ngModelChange)="emitFiltersChanged()"
                   ngbDatepicker #dateTo="ngbDatepicker"
                   [minDate]="minToDate"
                   [maxDate]="maxDate"
                   (dateSelect)="dateSelectChange('to')"
                   [footerTemplate]="datepickerToFooter"
                   (keyup.enter)="applyFiltersEmit()">
            <button
                class="btn btn-outline-primary"
                type="button"
                id="date_to"
                (click)="dateTo.toggle()"
                [disabled]="disabled"
            >
                <fa-icon icon="calendar-alt"></fa-icon>
            </button>

            <ng-template #datepickerToFooter>
                <hr class="my-0">
<!--                <button class="btn btn-primary btn-sm m-2 float-start" (click)="filters.to = currentDate;dateTo.close()">Today</button>-->
                <button class="btn btn-secondary btn-sm m-2 float-end" (click)="clearTo();emitFiltersChanged();dateTo.close()">Clear</button>
            </ng-template>
        </div>

        <ngb-timepicker *ngIf="useTime"
                        [(ngModel)]="time.to"
                        (ngModelChange)="emitFiltersChanged()"
                        [spinners]="false"
                        [disabled]="disabled || !filters?.to"
                        [ngbTooltip]="!filters?.to ? 'Please select date first' : 'Select time'"
                        class="form-control-sm p-0"
        />
    </div>
    <div class="mb-3 col d-flex align-items-end justify-content-end justify-content-sm-start" *ngIf="showClearButton || showApplyButton">
        <button class="btn btn-sm btn-secondary me-2"
                *ngIf="showClearButton"
                (click)="clearFilters()"
        >
            Clear
        </button>
        <button class="btn btn-sm btn-success"
                *ngIf="showApplyButton"
                [disabled]="disabled"
                (click)="applyFiltersEmit()"
        >
            Apply
        </button>
    </div>
</div>

import { Component, HostBinding, TemplateRef } from '@angular/core';
import { ToastrService } from '../../services/toastr.service';

@Component({
    selector: 'app-toastr-container',
    templateUrl: './toastr-container.component.html',
    styleUrls: ['./toastr-container.component.scss']
})
export class ToastrContainerComponent
{
    @HostBinding('class.ngb-toasts') get initClass(): boolean
    {
        return true;
    }

    public constructor(public toastrService: ToastrService)
    {}

    public isTemplate = toast => toast.textOrTpl instanceof TemplateRef;

    public isAutoHide = (toast): boolean => toast.autohide !== undefined ? toast.autohide : true;
}

<a *ngIf="partId | buildPartLink:partNumber as href; else partNumberTmpl"
   target="_blank"
   [href]="href"
   [style.color]="styled ? 'inherit' : ''"
   [class.clickable-item]="styled">
    {{ preparedName }}
</a>
<ng-template #partNumberTmpl>
    {{ preparedName }}
</ng-template>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {
    NgbDateAdapter,
    NgbDateParserFormatter,
    NgbModule,
    NgbTimeAdapter,
    NgbTooltipConfig
} from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomAdapter } from '../core/application/utils/date/date.adapter';
import { CustomDateParserFormatter } from '../core/application/utils/date/date.parser';
import { CustomTimeAdapter } from '../core/application/utils/time/time.adapter';
import { BreadcrumbsComponent } from './application/components/breadcrumbs/breadcrumbs.component';
import { InlineLoaderComponent } from './application/components/inline-loader/inline-loader.component';
import { LoaderComponent } from './application/components/loader/loader.component';
import { MonthFilterComponent } from './application/components/month-filter/month-filter.component';
import { NotesGridComponent } from './application/components/notes-grid/notes-grid.component';
import { TfootComponent } from './application/components/tfoot/tfoot.component';
import { ToastrContainerComponent } from './application/components/toastr-container/toastr-container.component';
import { NumericDirective } from './application/directives/numeric/numeric.directive';
import { BStatusPipe } from './application/pipes/b-status/b-status.pipe';
import { BdatePipe } from './application/pipes/bdate/bdate.pipe';
import { IndianCurrencyPipe } from './application/pipes/indian-currency/indian-currency.pipe';
import { DateFilterComponent } from './application/components/date-filter/date-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutofocusDirective } from './application/directives/autofocus/autofocus.directive';
import { OrderByPipe } from './application/pipes/order-by/order-by.pipe';
import { BooleanLabelPipe } from './application/pipes/boolean-label/boolean-label.pipe';
import { BuildPartLinkPipe } from './application/pipes/build-part-link/build-part-link.pipe';
import { PartLinkComponent } from './application/components/part-link/part-link.component';
import { MaxNumberDirective } from './application/directives/max-number/max-number.directive';
import { CopyClipboardDirective } from './application/directives/copy-clipboard/copy-clipboard.directive';
import { SortColumnDirective } from './application/directives/sort-column/sort-column.directive';
import { FileUploaderDirective } from './application/directives/file-uploader/file-uploader.directive';
import { ThComponent } from './application/components/tables/th/th.component';
import { SafeHtmlPipe } from './application/pipes/safe-html.pipe';
import { SearchDropdownComponent } from './application/components/search-dropdown/search-dropdown.component';
import { CustomValidityDirective } from './application/directives/custom-validity/custom-validity.directive';
import { DateAgoPipe } from './application/pipes/date-ago/date-ago.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { DynamicControlComponent } from './application/components/dynamic-control/dynamic-control.component';
import { TrimOnEventDirective } from './application/directives/trim-on-event/trim-on-event.directive';
import { DatePaginationComponent } from './application/components/tables/date-pagination/date-pagination.component';
import { AppliedFiltersComponent } from './application/components/applied-filters/applied-filters.component';
import { PinControlComponent } from './application/components/pin-control/pin-control.component';
import { GstControlComponent } from './application/components/gst-control/gst-control.component';
import { EventHistoryGridComponent } from './application/components/event-history-grid/event-history-grid.component';
import { BTitleCasePipe } from './application/pipes/b-title-case/b-title-case.pipe';
import { AppliedFiltersTogglerComponent } from './application/components/applied-filters-toggler/applied-filters-toggler.component';
import { FormControlErrorComponent } from './application/components/form-control-error/form-control-error.component';
import { DebugTargetComponent } from './application/components/debug-target/debug-target.component';
import { FullPartNamePipe } from './application/pipes/full-part-name/full-part-name.pipe';
import { TableFiltersComponent } from './application/components/table-filters/table-filters.component';
import { FilterByPipe } from './application/pipes/filter-by/filter-by.pipe';
import {NgbdTooltipConfig} from "../core/application/utils/cusom-ngb-config";
import { MapModalComponent } from './application/components/map-modal/map-modal.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SortCustomerTariffPlans } from "./application/pipes/sort-customer-tariff-plans/sort-customer-tariff-plans.pipe";


@NgModule({
    declarations: [
        BdatePipe,
        IndianCurrencyPipe,
        LoaderComponent,
        InlineLoaderComponent,
        DateFilterComponent,
        AutofocusDirective,
        OrderByPipe,
        FilterByPipe,
        BooleanLabelPipe,
        BuildPartLinkPipe,
        PartLinkComponent,
        MaxNumberDirective,
        CopyClipboardDirective,
        SortColumnDirective,
        FileUploaderDirective,
        ThComponent,
        TfootComponent,
        NumericDirective,
        SafeHtmlPipe,
        SearchDropdownComponent,
        CustomValidityDirective,
        DateAgoPipe,
        DynamicControlComponent,
        TrimOnEventDirective,
        DatePaginationComponent,
        NotesGridComponent,
        AppliedFiltersComponent,
        PinControlComponent,
        GstControlComponent,
        EventHistoryGridComponent,
        BTitleCasePipe,
        AppliedFiltersTogglerComponent,
        FormControlErrorComponent,
        BStatusPipe,
        ToastrContainerComponent,
        BreadcrumbsComponent,
        DebugTargetComponent,
        FullPartNamePipe,
        TableFiltersComponent,
        MonthFilterComponent,
        MapModalComponent,
        SortCustomerTariffPlans
    ],
    imports: [
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        NgbModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        NgSelectModule,
        RouterModule,
        GoogleMapsModule,
    ],
    exports: [
        BdatePipe,
        IndianCurrencyPipe,
        LoaderComponent,
        InlineLoaderComponent,
        NgbModule,
        NgxPaginationModule,
        FontAwesomeModule,
        DateFilterComponent,
        AutofocusDirective,
        OrderByPipe,
        FilterByPipe,
        PartLinkComponent,
        BooleanLabelPipe,
        MaxNumberDirective,
        CopyClipboardDirective,
        SortColumnDirective,
        FileUploaderDirective,
        ThComponent,
        TfootComponent,
        NumericDirective,
        SafeHtmlPipe,
        SearchDropdownComponent,
        CustomValidityDirective,
        DateAgoPipe,
        DynamicControlComponent,
        TrimOnEventDirective,
        DatePaginationComponent,
        NotesGridComponent,
        AppliedFiltersComponent,
        PinControlComponent,
        GstControlComponent,
        EventHistoryGridComponent,
        BTitleCasePipe,
        AppliedFiltersTogglerComponent,
        FormControlErrorComponent,
        BStatusPipe,
        ToastrContainerComponent,
        BreadcrumbsComponent,
        DebugTargetComponent,
        FullPartNamePipe,
        TableFiltersComponent,
        MonthFilterComponent,
        SortCustomerTariffPlans,
    ],
    providers: [
        BdatePipe,
        DateAgoPipe,
        IndianCurrencyPipe,
        OrderByPipe,
        FilterByPipe,
        {provide: NgbTimeAdapter, useClass: CustomTimeAdapter},
        {provide: NgbDateAdapter, useClass: CustomAdapter},
        {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
        {provide: NgbTooltipConfig, useClass: NgbdTooltipConfig}
    ]
})
export class SharedModule
{
    constructor(library: FaIconLibrary)
    {
        library.addIconPacks(fas, far);
    }
}

import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bdate'
})
export class BdatePipe extends DatePipe implements PipeTransform
{
    private readonly customFormats = {
        dateInput: 'yyyy-MM-dd',
        date: 'dd-MM-yyyy',
        time: 'HH:mm',
        dateTime: ``,
        excel: 'yyyy-MM-ddTHH:mm:ss.mmm',
    };
    constructor(@Inject(LOCALE_ID) locale='en-US') {
        super(locale);
        this.customFormats.dateTime = `${this.customFormats.date} ${this.customFormats.time}`;
    }

    transform(value: null | undefined | Date | string | number, format?: string, timezone?: string, locale?: string): null;
    transform(
        value: string | number | Date,
        format: 'dateInput' | 'date' | 'time' | 'excel' | 'dateTime' | 'monthYear' | string = 'date',
        timezone?: string,
        locale?: string
    ): string
    {
        if (!value) {
            return null;
        }
        format = this.customFormats[format] || format;
        if (format === 'monthYear') {
            const date = new Date(value);
            return date.toLocaleString('en-IN', {year: 'numeric', month: 'long'});
        }

        return super.transform(value, format, timezone, locale);
    }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, retry } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { DictionaryRepository } from '../../infrastructure/repositories/dictionary.repository';
import { StateInterface } from '../../domain/models/state.interface';
import { CityInterface } from '../../domain/models/city.interface';
import { CheckAddressResponseInterface } from '../../infrastructure/response/check-address-response.interface';
import { CheckAddressInterface } from '../../domain/models/check-address.interface';

@Injectable({
    providedIn: 'root'
})
export class DictionaryService
{
    readonly stateList$ = new Observable<StateInterface[]>();
    private readonly stateListSource$ = new BehaviorSubject<StateInterface[]>(null);
    constructor(private dictionaryRepository: DictionaryRepository)
    {
        this.stateList$ = this.stateListSource$.asObservable().pipe(
            retry({
                count: 1,
                delay: 800,
                resetOnSuccess: true
            }),
            map((list) => {
                if (!list) {
                    this.loadStateList().subscribe();
                    return;
                }
                return list;
            }),
            filter((v) => !!v)
        );
    }

    loadStateList = (): Observable<StateInterface[]> => this.dictionaryRepository.loadStateList().pipe(
        map((data) => data?.items || []),
        tap((res) => this.stateListSource$.next(res)),
    );

    loadCityList = (stateAbbr: string): Observable<CityInterface[]> =>
      this.dictionaryRepository.loadCityList(stateAbbr).pipe(map((items) => items));

    addressCheck = (payload: CheckAddressInterface): Observable<CheckAddressResponseInterface> => this.dictionaryRepository.addressCheck(payload);
}

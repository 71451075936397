import { environment } from '../environments/environment';

const MAIN_SERVER = `${environment.HTTP_PROTOCOL}://${environment.SITE_DOMAIN}`;
export const APP_CONFIG = {
    MAIN_SERVER,
    PLATFORM_SERVER : environment.PLATFORM_HOSTNAME,
    SELLER_SERVER   : `${environment.HTTP_PROTOCOL}://${environment.SELLER_HOSTNAME}`,
    ADMIN_SERVER   : `${environment.HTTP_PROTOCOL}://manager.${environment.SITE_DOMAIN}`,
    API: {
        MARKETPLACE: '/admin/',
        CUSTOMER: '/api/customer/',
        ADMIN: '/v1/admin/api/',
        ADMIN_V2: '/v2/admin/api/',
        SELLER: '/v1/seller/api/',
    },
    ESB_SERVER: environment.ESB_SERVER,
    CRM_SERVER: environment.CRM_SERVER,
    DOCUMENT_SERVER: environment.DOCUMENT_SERVER,
    ADMIN_VIEW_SERVER: environment.ADMIN_VIEW_SERVER,
    MEDIA: '/media',
    MEDIA_SERVER: '/tocan', // tmp use TOCAN
    STORAGE: MAIN_SERVER + '/storage' // TODO use different storage for local build
};

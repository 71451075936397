import { Subject } from 'rxjs';
import { BdatePipe } from '../../../../shared/application/pipes/bdate/bdate.pipe';
import { IndianCurrencyPipe } from '../../../../shared/application/pipes/indian-currency/indian-currency.pipe';
import { DateUtil } from '../date/date.util';
import { QueryBuilderCore } from './query-builder-core';
import { HttpParams } from '@angular/common/http';

/**
 * TODO:
 * - Check that method changeLimit and changePageNum is valid
 * - Tests
 */
export class FilterQueryBuilder extends QueryBuilderCore
{
    static MAX_LIMIT = 1000;
    applyFilters = new Subject<Record<string, any> | boolean>();

    private FILTER_KEY = 'filters';
    private SORTING_KEY = 'sorting';
    private PAGE_KEY = 'page';
    private LIMIT_KEY = 'limit';
    private OFFSET_KEY = 'offset';

    private SORT_ASK = 'asc';
    private SORT_DESK = 'desc';

    /* Active page */
    private page: number;

    /* Limit rows per page */
    private limit: number;

    /* Offset items, instead of active page */
    private offset: number;

    /* Multiselect filters */
    private readonly multiple: boolean;

    /* Fix when back expects an array value */
    private readonly isSingleValueInArray: boolean;

    /* Filter objects from inputs (with keys, values) */
    private filters: Record<string, unknown> = {};

    /* Sorting object from table titles */
    private sorting: Record<string, unknown> = {};

    /* List of filters, that can be used for filtering. Need for validating */
    private filterSet = [];

    /* Filter objects from inputs (with keys, values) grouped by rangeSuffix */
    private _appliedFilters: Record<string, any> = {};

    /* Final object that will be send to API */
    private settings = {};

    public constructor(config: FilterQueryConfigInterface)
    {
        super();

        const {filterSet, page, limit, offset, multiple, isSingleValueInArray} = config;

        if (page) {
            this.page = page || 1;
        }
        if (limit) {
            this.limit = limit || 10;
        }
        if (offset !== undefined) {
            this.offset = offset || 0;
        }
        this.filterSet = filterSet || [];
        this.multiple = multiple ?? true;
        this.isSingleValueInArray = isSingleValueInArray ?? true;
    }

    public getTotalPages(totals): number
    {
        return Math.ceil(totals / this.getLimit());
    }

    public getPage(): number
    {
        return this.page;
    }

    public getLimit(): number
    {
        return this.limit;
    }

    public getOffset(): number
    {
        return this.offset;
    }

    public getFilters(): Record<string, unknown>
    {
        return this.filters;
    }

    get appliedFilters(): Record<string, any>
    {
        return {...this._appliedFilters};
    }

    get appliedFiltersCount(): number
    {
        return Object.keys(this.appliedFilters).length;
    }

    getFilterSet(): string[]
    {
        return this.filterSet;
    }

    public getSorting(): Record<string, unknown>
    {
        return this.sorting;
    }

    public changeLimit(limit: number): void
    {
        this.limit = limit;
    }

    public changeOffset(offset: number): void
    {
        this.offset = offset;
    }

    public changePageNum(page: number): void
    {
        this.page = page;
    }

    public setFilterSet(filterSet: string[]): void
    {
        this.filterSet = [
            ...this.filterSet,
            ...filterSet
        ];
    }

    public handleFilter(filterKey: string, value: any, config?: HandleFilterConfigInterface): void
    {
        if (!this.checkValueBeforeAddFilter(filterKey, value, config?.rangeSuffix)) {
            return;
        }

        let preparedValue = this.castBooleanFilter(value?.id || value?.id === null ? value?.id : value);
        let preparedLabel = value;

        if (!this._appliedFilters[filterKey]) {
            this._appliedFilters[filterKey] = {};
        }

        if (config?.moneyInPennies || config?.isMoney) {
            preparedLabel = new IndianCurrencyPipe().transform(config?.moneyInPennies
                ? preparedValue
                : preparedValue * 100
            );
        }

        if (config?.rangeSuffix) {
            if (config?.isDate) {
                preparedValue = value;
                const rangeDate = DateUtil.convertTimestampToDate(value);
                const endRangeDate = new Date(rangeDate.getTime() + 1000);

                const needShowTime = !((!rangeDate.getHours() && !rangeDate.getMinutes()) || (!endRangeDate.getHours() && !endRangeDate.getMinutes()));

                preparedLabel = (config?.dateInSeconds && !needShowTime)
                    ? DateUtil.convertDateToInputFormat(rangeDate)
                    : new BdatePipe().transform(
                        config.dateWithTime === false
                            ? DateUtil.convertStringToDbFormat(value)
                            : config?.dateInSeconds
                                ? value * 1000
                                : (value.replace(/\s/, 'T') + 'Z'),
                        needShowTime ? 'dateTime' : 'date'
                    );
            }

            if (config?.isMonth) {
                preparedValue = value;
                preparedLabel = new BdatePipe().transform(new Date(value.slice(0,4), value.slice(4)-1), 'monthYear');
            }

            this._appliedFilters[filterKey][config?.rangeSuffix?.toLowerCase()?.replace('_', '')] = preparedLabel || preparedValue;
            filterKey += config?.rangeSuffix;
        } else {
            this._appliedFilters[filterKey] = preparedLabel;
        }

        if (!this.multiple || (config?.multiple !== undefined && !config.multiple)) {
            this.filters[filterKey] = Array.isArray(preparedValue) ? preparedValue.map(item => item.id) : preparedValue;
        } else {
            const filter = this.filters[filterKey] ?? [];

            const arr = Array.isArray(preparedValue) ? preparedValue.map(item => item.id) : preparedValue;

            this.filters[filterKey] = this.isSingleValueInArray || !Array.isArray(filter)
                ? [arr]
                : filter.concat([arr]);
        }
    }

    public handleSort(sortingKey: string, value: string): void
    {
        const sortValue = value.toLocaleLowerCase();

        if (![this.SORT_ASK, this.SORT_DESK].includes(sortValue)) {
            return;
        }

        this.sorting = {};

        this.sorting[sortingKey] = sortValue;
    }

    public buildRequestObject(): void
    {
        this.buildPageLimit();

        this.buildFilters();

        this.buildSorting();

        this.request = this.settings;
    }

    public clearFilters(): void
    {
        this.settings = {};
        this.filters = {};
        this._appliedFilters = {};
        this.request = {};
    }

    public clearSort(): void
    {
        this.sorting = {};
    }

    public buildHttpParams(skipPagination = false): { params: HttpParams }
    {
        const sorting = Object.keys(this.sorting)[0];

        let params = new HttpParams();

        if (!skipPagination && this.page) {
            params = params.set(this.PAGE_KEY,this.page.toString());
        }

        if (!skipPagination && this.limit) {
            params = params.set(this.LIMIT_KEY,this.limit.toString());
        }

        if (!skipPagination && this.offset !== undefined) {
            params = params.set(this.OFFSET_KEY,this.offset.toString());
        }

        if (this.sorting[sorting]) {
            params = params.set(this.SORTING_KEY, this.sorting[sorting] === this.SORT_DESK ? `-${sorting}` : sorting);
        }

        for (const [key, value] of Object.entries(this.filters)) {
            if (value instanceof Array) {
                for (const item of value) {
                    const filterKey = this.isSingleValueInArray ? `filters[${key}]` : `filters[${key}][${value.indexOf(item)}]`;
                    params = params.set(filterKey, item);
                }
            } else {
                params = params.set(`filters[${key}]`, value as any);
            }
        }

        return {
            params,
        };
    }

    private checkValueBeforeAddFilter(filterKey: string, value: any, rangeSuffix?: string): boolean
    {
        const appliedFilterKye = filterKey.slice();
        if (value === '' || value === null || value === undefined || (Array.isArray(value) && !value?.length)) {
            if (rangeSuffix) {
                filterKey += rangeSuffix;
            }
            if (appliedFilterKye in this._appliedFilters) {
                delete this._appliedFilters[appliedFilterKye][rangeSuffix?.toLowerCase()?.replace('_', '')];
                if (!(Object.keys(this._appliedFilters[appliedFilterKye])?.length > 0) || !rangeSuffix) {
                    delete this._appliedFilters[appliedFilterKye];
                }
            }
            if (filterKey in this.filters) {
                delete this.filters[filterKey];
            }
            return false;
        }
        if (rangeSuffix) {
            filterKey += rangeSuffix;
        }
        return this.filterSet.includes(filterKey);
    }

    private buildPageLimit(): void
    {
        // this.page = Object.keys(this.filters).length ? 1 : this.page;
        this.settings[this.PAGE_KEY] = this.page;
        this.settings[this.LIMIT_KEY] = this.limit;
        this.settings[this.OFFSET_KEY] = this.offset;
    }

    private buildFilters(): void
    {
        this.settings[this.FILTER_KEY] = {};

        for (const filter in this.filters) {
            this.settings[this.FILTER_KEY][filter] = this.filters[filter];
        }
    }

    private buildSorting(): void
    {
        this.settings[this.SORTING_KEY] = {};

        this.settings[this.SORTING_KEY] = this.sorting;
    }

    private castBooleanFilter = (filter: any): any => {
        if (filter === 'true' || filter === 'false') {
            return JSON.parse(filter.toLowerCase());
        }
        return filter;
    };
}

export interface FilterQueryConfigInterface
{
    filterSet?: string[];
    page?: number;
    limit?: number;
    offset?: number;
    multiple?: boolean;
    isSingleValueInArray?: boolean;
}

export interface HandleFilterConfigInterface
{
    moneyInPennies?: boolean;
    isMoney?: boolean;
    dateInSeconds?: boolean;
    dateWithTime?: boolean;
    isDate?: boolean;
    isMonth?: boolean;
    rangeSuffix?: string;
    multiple?: boolean;
    isSingleValueInArray?: boolean;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateFilterInterface } from '../../date-filter/date-filter.component';
import { DateUtil } from '../../../../../core/application/utils/date/date.util';

@Component({
    selector: 'app-date-pagination',
    templateUrl: './date-pagination.component.html',
    styleUrls: ['./date-pagination.component.scss']
})
export class DatePaginationComponent implements OnInit
{

    @Input() readonly dateDifference: number;
    @Input() readonly disabled: boolean;
    @Input() readonly filterFromKey: string = 'createdAtFrom';
    @Input() readonly filterToKey: string = 'createdAtTo';
    @Input() readonly selectedDate: DateFilterInterface;

    @Output() filtersChanged: EventEmitter<DateFilterInterface> = new EventEmitter<DateFilterInterface>();

    initialized: boolean;

    constructor() {/*empty*/}

    ngOnInit(): void
    {
        const currentDate = new Date(new Date().setHours(0, 0, 0, 0) - new Date().getTimezoneOffset() * 60000);
        this.setDateFilter(DateUtil.addDays(currentDate, -this.dateDifference), currentDate);
        this.initialized = true;
    }

    isLastPage(): boolean
    {
        return new Date().setHours(0,0,0,0) < new Date(this.getNextDate.from).getTime();
    }

    onChangePagination(period: DateFilterInterface): void
    {
        this.setDateFilter(new Date(period.from), new Date(period.to));
    }

    get getPrevDate(): DateFilterInterface
    {
        return {
            from: DateUtil.addDays(new Date(this.selectedDate.from), -(this.dateDifference + 1), true).toISOString().slice(0, 10),
            to: DateUtil.addDays(new Date(this.selectedDate.from), -1, true).toISOString().slice(0, 10)
        };
    }

    get getNextDate(): DateFilterInterface
    {
        return {
            from: DateUtil.addDays(new Date(this.selectedDate.to), 1, true).toISOString().slice(0, 10),
            to: DateUtil.addDays(new Date(this.selectedDate.to), this.dateDifference + 1, true).toISOString().slice(0, 10),
        };
    }

    private setDateFilter(fromDate: Date, toDate: Date): void
    {
        const from = fromDate.toISOString().slice(0, 10);
        const to = toDate.toISOString().slice(0, 10);
        this.selectedDate.from = from;
        this.selectedDate.to = to;
        this.filtersChanged.emit({from, to});
    }
}

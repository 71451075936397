import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-applied-filters-toggler',
    templateUrl: './applied-filters-toggler.component.html',
    styleUrls: ['./applied-filters-toggler.component.scss']
})
export class AppliedFiltersTogglerComponent
{
    @Input() appliedFiltersCount: number;
    @Output() toggleFilters = new EventEmitter();
    collapsed = true;
    constructor() {/*empty*/}
}

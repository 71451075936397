import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractForm } from '../../../../core/application/forms/abstract.form';

@Component({
    selector: 'app-dynamic-control',
    templateUrl: './dynamic-control.component.html',
    styleUrls: ['./dynamic-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DynamicControlComponent),
            multi: true
        }
    ],
})
export class DynamicControlComponent implements OnInit, ControlValueAccessor
{

    @Input() readonly controlKey: string;
    @Input() readonly errorPath: string;
    @Input() readonly fb: AbstractForm;
    @Input() readonly form: UntypedFormGroup;
    @Input() readonly label: string;
    @Input() readonly options: Record<string, string>[];
    @Input() readonly required: boolean;
    @Input() readonly type: 'text' | 'textarea' | 'select' | 'checkbox' | 'password';

    disabled = false;
    value: any;

    constructor() {/*empty*/}

    ngOnInit(): void {
        if (this.type === 'checkbox') {
            this.writeValue(false);
        }
    }

    onChange: any = () => {/*empty*/};
    onTouched: any = () => {/*empty*/};

    writeValue(value: any): void
    {
        this.value = value;
        this.onChange(this.value);
    }

    registerOnChange(fn: any): void
    {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void
    {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void
    {
        this.disabled = isDisabled;
    }

}

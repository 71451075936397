import { ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * @Deprecated
 */
@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchDropdownComponent),
      multi: true
    }
  ]
})
export class SearchDropdownComponent implements OnInit, OnChanges
{
  @Input() readonly keyName: string;
  @Input() readonly valueName: string;
  @Input() readonly placeholder: string;
  @Input() readonly options: Record<string, string>[];
  @Input() readonly objectValue: boolean;
  @Input() readonly container: null | 'body' = null;

  disabled = false;
  filteredOptions: Record<string, string>[];
  value: any;
  searchValue: string;

  constructor(
      private cd: ChangeDetectorRef,
  )
  { }

  ngOnInit(): void
  {
      setTimeout(() => {
          this.filteredOptions = [...this.options];
      });
  }

  ngOnChanges(changes: SimpleChanges): void
  {
      if (changes.options) {
          this.filteredOptions = [...this.options];
          this.search();
          this.cd.detectChanges();
      }
  }

  search(): void
  {
    this.filteredOptions = this.options?.filter((o) =>
        this.getLabel(o)?.toLowerCase().includes(this.searchValue?.toLowerCase()));
  }

  onChange: any = () => {/*empty*/};
  onTouched: any = () => {/*empty*/};

  writeValue(value: any): void
  {
    this.searchValue = '';
    this.value = value;
    this.onChange(this.value);
  }

  clearValue(): void
  {
    this.searchValue = '';
    this.value = '';
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void
  {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void
  {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void
  {
    this.disabled = isDisabled;
  }

  getLabel(item): string
  {
      return !item ? '' : String(this.keyName ? item && item[this.keyName] : item);
  }

  getValue(item)
  {
      return  this.objectValue || !this.keyName? item : item && item[this.valueName] ;
  }

}

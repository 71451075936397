import { Injectable } from '@angular/core';
import { AbstractRepository } from '../../../core/infrastructure/repositories/abstract.repository';
import { Observable } from 'rxjs';
import { StateInterface } from '../../domain/models/state.interface';
import { CityInterface } from '../../domain/models/city.interface';
import { CheckAddressInterface } from '../../domain/models/check-address.interface';
import { CheckAddressResponseInterface } from '../response/check-address-response.interface';

@Injectable({
    providedIn: 'root'
})
export class DictionaryRepository extends AbstractRepository {

    loadStateList = (): Observable<{list: number; items: StateInterface[]}> =>
        this.httpClient.get<{list: number; items: StateInterface[]}>(this.buildUrl(`location/state-list`));

    loadCityList = (stateAbbr: string): Observable<CityInterface[]> =>
        this.httpClient.get<CityInterface[]>(this.buildUrl(`location/state/${stateAbbr}/city/list`));


    addressCheck = (payload: CheckAddressInterface): Observable<CheckAddressResponseInterface> =>
      this.httpClient.post<CheckAddressResponseInterface>(this.buildUrl(`crm/customer/address/check`), payload);
}

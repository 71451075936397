/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter
{
    // mask string: 'dd-mm-yyyy'
    // or if isNative = true (for parse): 'yyyy-mm-dd';
    readonly DELIMITER = '-';

    parse(value: string, isNative?): NgbDateStruct
    {
        let result: NgbDateStruct = null;
        if (value) {
            const date = value.split(this.DELIMITER);
            result = {
                day: parseInt(date[isNative ? 2 : 0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[isNative ? 0 : 2], 10)
            };
        }
        return result;
    }

    format(date: NgbDateStruct): string
    {
        let result: string = null;
        if (date) {
            result = date.day.toString(10).padStart(2, '0') + this.DELIMITER + date.month.toString(10).padStart(2, '0') + this.DELIMITER + date.year;
        }
        return result;
    }
}

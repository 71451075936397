<div class="fs-lg d-flex flex-wrap align-items-center"
>
<!--     *ngIf="hasFilters"-->
<!--     [@inOutAnimation]-->
    <span class="mb-2 me-2" *ngIf="title">
        {{title}}
    </span>

    <span *ngIf="containerClassList; else appliedFiltersContainer"
          [ngClass]="containerClassList"
    >
        <ng-container *ngTemplateOutlet="appliedFiltersContainer"></ng-container>
    </span>
    <ng-template #appliedFiltersContainer>
        <ng-container *ngIf="hasFilters; else noAppliedFilters">
            <span *ngFor="let filter of appliedFilters | keyvalue"
                  [ngClass]="filtersClassList"
            >
            <span [title]="getFilterValue(filter.value)"
                  class="d-inline-block text-truncate text-truncate-lg"
            >{{ getFilterTitle(filter.key) }}: {{ getFilterValue(filter.value) }}</span>
            <button type="button" class="btn-close btn-close-white" aria-label="Close"
                    *ngIf="useClearFilter"
                    (click)="onClearFilter(filter.key)"></button>
            </span>
        </ng-container>
    </ng-template>

    <ng-template #noAppliedFilters>
        <span class="badge bg-secondary me-2 mb-2">No applied filters</span>
    </ng-template>
</div>

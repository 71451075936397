import { ColumnInterface, ConfigInterface } from '../../../../shared/application/components/tables/th/th.component';

export class GridHelper
{
    static ClearSorting(cols: {[key: string]: ColumnInterface}, sortCol: ConfigInterface): void
    {
        Object.values(cols).forEach((col) => {
            if (col.sort && sortCol !== col.sort) {
                col.sort.applied = false;
                col.sort.value = null;
            }
        });
    }

    static ClearAllFilters(cols: {[key: string]: ColumnInterface}, sort?: boolean): void
    {
        for (const col of Object.values(cols)) {
            if (sort && col.sort) {
                col.sort.applied = false;
                col.sort.value = null;
            }

            if (col.filter) {
                col.filter.applied = false;
                col.filter.value = col.filter.initValue !== undefined ? col.filter.initValue : null;
            }
        }
    }
}

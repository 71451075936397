import { Pipe, PipeTransform } from '@angular/core';
import _sortBy from 'lodash-es/sortBy';
import _orderBy from 'lodash-es/orderBy';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform
{

    transform<T>(collection: T[], iteratees: string | string[] = '', orders: boolean | 'asc' | 'desc' |  string[] = false): T[] {
        if (!Array.isArray(iteratees)) {
            iteratees = iteratees == null ? [] : [iteratees];
        }
        if (typeof orders === 'boolean') { orders = [orders ? 'asc' : 'desc']; } // reverse
        if (!collection) { return collection; } // no array
        if (!iteratees.length) { return _sortBy(collection); } // sort 1d array
        if (collection.length <= 1) { return collection; } // array with only one item
        return _orderBy(collection, iteratees, orders);
    }
}

<ng-template #defaultContext>No data</ng-template>
<a role="button" class="ms-1 btn-link"
   *ngIf="canShowInDebugPanel; else defaultContext"
   ngbTooltip="Click to copy context for show in debug panel"
   container="body"
   (click)="debug(info)"
>
    <span *ngIf="ref.children.length === 0">Context</span>
    <div #ref>
        <ng-content></ng-content>
    </div>
</a>

export const DEFAULT_ROUTE = {
    loggedIn: '/sales/orders',
    loggedOut: '/login',
    dashboard: '/dashboard',
    accessDenied: '/access-denied',
    crm: {
      customers: '/crm/customers',
      customer: '/crm/customer',
      payments: '/crm/payments',
      refunds: '/crm/refunds'
    },
    sales: {
        orders: '/sales/orders',
        claims: '/logistic/claims',
        returns: '/sales/returns',
        forwardLogistics: '/logistic/forward-logistics',
        backwardLogistics: '/logistic/backward-logistics',
        invoices: '/sales/invoices',
        creditNotes: '/sales/credit-notes',
    },
    logistic: {},
    pim: {
        masterPlBlocklist: '/pim/mrp-tool/master-pl-blocklist',
        part: {
            brands: '/pim/brands',
            classes: '/pim/classes',
        },
        pages: '/pim/pages',
    },
    srm: {
        sellers: '/srm/sellers',
        seller: '/srm/seller',
    },
    finance: {
        transactions: '/finance/transactions',
    },
    billing: {
        billingHistoryUsage: '/billing/history-usage',
    }
};

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GridHelper } from '../../../../core/application/utils/grid/grid-helper';
import { FilterQueryBuilder } from '../../../../core/application/utils/query-builder/filter-query-builder';
import { BTitleCasePipe } from '../../pipes/b-title-case/b-title-case.pipe';
import { ColumnInterface, ThComponent } from '../tables/th/th.component';

@Component({
    selector: 'app-applied-filters',
    templateUrl: './applied-filters.component.html',
    styleUrls: ['./applied-filters.component.scss'],
})
export class AppliedFiltersComponent implements OnInit, OnDestroy
{
    @Input() queryBuilder: FilterQueryBuilder;
    @Input() useClearFilter: boolean;
    @Input() readonly cols: { [key: string]: ColumnInterface };
    @Input() readonly dataList: any[];
    @Input() readonly title?: string = 'Filters:';
    @Input() readonly filtersClassList?: string | string[] = ['badge', 'bg-dark', 'me-2', 'mb-2', 'cursor-pointer', 'd-inline-flex', 'align-items-center'];
    @Input() readonly containerClassList?: string | string[];
    @Input() readonly withUnsubscribe?: boolean = true;
    @Output() clearFilter = new EventEmitter();
    appliedFilters = {};

    constructor() {/*empty*/}

    ngOnInit(): void
    {
        this.queryBuilder.applyFilters.subscribe(() => {
            this.appliedFilters = this.queryBuilder.appliedFilters;
        });
    }

    ngOnDestroy()
    {
        if (this.withUnsubscribe) {
            this.queryBuilder.applyFilters?.unsubscribe();
        }
    }

    get hasFilters(): boolean
    {
        return Boolean(Object.keys(this.appliedFilters).length);
        // return Boolean(this.queryBuilder?.appliedFiltersCount);
    }

    getFilterTitle(filterName: string): string
    {
        if (!this.cols) {
            return '';
        }

        const titleKey = Object.values(this.cols).find((column) => column.filter?.name === filterName)?.filter?.title || filterName;
        return new BTitleCasePipe().transform(titleKey);
    }

    getFilterValue(filter: string | number | RangeInterface | any): string
    {
        let result = '';

        switch (true) {
            case typeof filter !== 'object':
                result = filter;
                break;
            case Array.isArray(filter):
                result = filter.map(item => item.name).join(', ');
                break;
            case !!filter?.name:
                result = filter.name;
                break;
            case !!(filter?.from || filter?.to):
                if (filter?.from) {
                    result += `from ${filter?.from}`;
                }
                if (filter?.to) {
                    if (result) {
                        result += ' ';
                    }
                    result += `to ${filter?.to}`;
                }
                break;
        }

        return result;
    }

    onClearFilter(filterKey: string)
    {
        const col = {};
        col[filterKey] = this.cols[filterKey];
        GridHelper.ClearAllFilters(col);
        if (ThComponent.filterIsRange(col[filterKey])) {
            for (const rangeFilterSuffix of ['From', 'To', '_from', '_to']) {
                this.queryBuilder.handleFilter(filterKey, '', {rangeSuffix: rangeFilterSuffix});
            }
        } else {
            this.queryBuilder.handleFilter(filterKey, col[filterKey].filter?.initValue ?? '');
        }
        this.clearFilter.emit(filterKey);
    }
}

interface RangeInterface
{
    from: number | string;
    to: number | string;
}

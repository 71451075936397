import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterQueryBuilder } from '../../../../core/application/utils/query-builder/filter-query-builder';

@Component({
    selector: 'app-tfoot',
    templateUrl: './tfoot.component.html',
    styleUrls: ['./tfoot.component.scss']
})
export class TfootComponent
{
    @Input() id?: string;
    @Input() dataList: any[];
    @Input() loader: any;
    @Input() queryBuilder?: FilterQueryBuilder;
    @Input() currentPage?: number;
    @Input() itemsPerPage?: number;
    @Input() useMoreItems?: boolean;
    @Input() total?: number | null;
    @Output() changePageNum: EventEmitter<number> = new EventEmitter<number>();
    @Output() changeLimit: EventEmitter<number> = new EventEmitter<number>();

    get page(): number
    {
        return this.queryBuilder?.getPage() || this.currentPage;
    }

    get limit(): number
    {
        return this.queryBuilder?.getLimit() || this.itemsPerPage;
    }

    get totalItemsLength(): number
    {
        return this.total !== undefined ? this.total : this.dataList?.length;
    }

    get getNextLabel(): string
    {
        return this.totalItemsLength === null ? 'Next' : null;
    }

    changeItemsPerPage(limit: number)
    {
        if (this.queryBuilder) {
            this.queryBuilder.changeLimit(limit);
        }
        if (this.itemsPerPage) {
            this.itemsPerPage = limit;
        }
        this.changeLimit.emit(limit);
        this.changePageNum.emit(1);
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullPartName'
})
export class FullPartNamePipe implements PipeTransform {

  transform(array: string[], divider = ', '): string {
      if (!array || !(array?.length > 0) ) {
          return '';
      }
      return array.filter(i => i?.length > 0).join(divider);
  }
}

import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appCustomValidity]'
})
export class CustomValidityDirective implements OnInit, OnDestroy {
  subscription$: Subscription;

  constructor(private control: NgControl, private el: ElementRef) {}

  ngOnInit(): void {
    this.subscription$ = this.control.statusChanges.subscribe(res => {
      this.el.nativeElement.setCustomValidity(res === 'INVALID' ? 'error' : '');
    });
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}

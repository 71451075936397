import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimOnEvent]'
})
export class TrimOnEventDirective
{

  @Input('appTrimOnEvent') readonly eventNames: string|string[];
  @Input() readonly trimMultipleSpaces = false;

  constructor(private control: NgControl)
  { }


  @HostListener('input', ['$event'])
  @HostListener('change', ['$event'])
  @HostListener('blur', ['$event'])
  onInput(e: Event): void
  {
    if (!(Array.isArray(this.eventNames) ? this.eventNames.includes(e.type) : e.type === this.eventNames)) return;

    let value = (e.target as HTMLInputElement).value;
    if (!value?.length) return;

    value = value.trim();
    if (this.trimMultipleSpaces) value = value.replace(/\s+/g, ' ');

    this.control.valueAccessor.writeValue(value.trim());
    this.control.control.patchValue(value.trim());
  }
}
